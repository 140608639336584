export const SurfaceType = {
    WALL: 'Wall',
    FLOOR: 'Floor',
    CEILING: 'Ceiling'
}

export const ProductPlacementType = {
    Product: 'product',
    Texture: 'texture',
    Color: 'color',
}

export const RoomViewType = {
    top: 'top',
    firstPersonView: 'firstPersonView',
    default: 'default'
}

export const DimensionalViewType = {
    TWO_D: '2D',
    THREE_D: '3D',
}

export const BUILDER_IN_PROGRESS = "inprogress";
export const BUILDER_SUCCESS = "success";
export const BUILDER_FAILED = "failed";
export const BUILDER_NOTIFICATION_INFO = "info";
export const BUILDER_NOTIFICATION_ERROR = "error";
export const BUILDER_NOTIFICATION_SUCCESS = "success";
export const TRIGGER_ROOM_LAYOUT_THUMBNAILS_SAVE = "triggerRoomLayoutThumbnailsSave";
