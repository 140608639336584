import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { createTheme, Theme } from "@mui/material/styles";
import { aamericaTheme } from "./aamerica";
import { flexsteelTheme } from "./flexsteel";
import { homestylesTheme } from "./homestyles";
import { nestingaleTheme } from "./nestingale";

const defaultThemeName = 'nestingaleTheme';

const themeMap: { [key: string]: Theme } = {
    nestingaleTheme,
    aamericaTheme,
    homestylesTheme,
    flexsteelTheme
}

export function themeCreator(theme: string): Theme {
    if (themeMap[theme]) {
        return themeMap[theme];
    } else {
        return themeMap[defaultThemeName];
    }
}

export function defaultTheme() {
    const paletteColors = {
        primary: {
            light: '#FFFFFF',
            main: '#0D908E',
            dark: '#25282B',
            contrastText: '#fff',            
        },
        secondary: {
            main: '#A0A4AB',
            dark: '#52575C',
            light: '#A0A4A8'
        },
        primary_extent: {
            mainDark: "#076665"
        },
        additional: {
            color_01: '#FFFFFF'
        },
        btn_colors: {
            color_01: '#003E58'
        },
        buttons_bg: {
            primary: '#FE6526',
            primary_hover: '#F98F45',
            secondary: '#FFFFFF',
            info: '#FFFFFF',
            primary_dark: '#f55817'            
        },
        buttons_text: {
            primary: '#FFFFFF',
            secondary: '#FE6526',
            secondary_hover: '#F98F45',
            info: '#095F6A',
            info_hover: '#138c9c'  
        },
        backgrounds: {
            bg_1: '#003E58',
            bg_2: '#DFF1F0',
            bg_3: '#FEF4EC',
            bg_4: '#095F6A',
        }
    }

    const defaultBreakPoints = {
        values: {
            xs: 0,
            sm: 560,
            md: 767,
            lg: 1200,
            xl: 1536,
        }
    }

    const customBreakpoints = createBreakpoints(defaultBreakPoints);
    return createTheme({
        breakpoints: customBreakpoints,
        palette: paletteColors,
        typography: {
            fontFamily: ['Nunito', 'Lora'].join(','),
            h1: {
                fontSize: '24px',
                color: paletteColors.secondary.dark,
                lineHeight: '32px',
                letterSpacing: '0.2px',
                fontWeight: 600,
                [customBreakpoints.down('sm')]: {
                    fontSize: '20px',
                    lineHeight: '26px'
                },
            },
            h2: {
                fontSize: '16px',
                color: paletteColors.primary.dark,
                lineHeight: '20px',
                letterSpacing: '0.2px',
                fontWeight: 600
            },
            h3: {
                fontSize: '16px',
                color: paletteColors.primary.dark,
                lineHeight: '20px',
                letterSpacing: '0.2px',
                fontWeight: 600,
                textTransform: 'uppercase'
            },
            body2: {
                color: paletteColors.primary.dark,
                fontSize: '14px',
                lineHeight: '22px',
                letterSpacing: '0.1px'
            },
            subtitle1: {
                color: paletteColors.primary.dark,
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.1px',
                fontWeight: 500
            },
            subtitle2: {
                color: paletteColors.primary.dark,
                fontSize: '14px',
                lineHeight: '22px',
                letterSpacing: '0.1px',
                textTransform: 'capitalize'
            }
        },
        components: {
            MuiTab: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        margin: '0 20px',
                        paddingLeft: 0,
                        paddingRight: 0,
                        minWidth: 'initial'
                    }
                }
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderColor: paletteColors.secondary.light
                    }
                }
            },
            MuiBreadcrumbs: {
                styleOverrides: {
                    root: {
                        fontSize: '14px',
                        color: paletteColors.primary.dark,
                        li: {
                            margin: '0px'
                        },
                        a: {
                            color: paletteColors.primary.dark,
                            '&:hover': {
                                color: paletteColors.primary.main
                            }
                        }
                    }
                }
            },
            MuiRating: {
                styleOverrides: {
                    root: {
                        color: paletteColors.primary.main,
                        fontSize: '20px'
                    }
                }
            },
            MuiButtonBase: {
                defaultProps: {
                    // The props to change the default for.
                    disableRipple: true, // No more ripple, on the whole application 💣!
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                        ...(ownerState.color === 'primary' && {
                            backgroundColor: paletteColors.buttons_bg.primary,
                            color: paletteColors.buttons_text.primary,
                            borderColor: paletteColors.buttons_text.primary,
                            textTransform: 'uppercase',
                            '&:hover': {
                                backgroundColor: paletteColors.buttons_bg.primary_hover,
                                color: paletteColors.buttons_text.primary,
                            }
                        }),
                        ...(ownerState.color === 'secondary' && {
                            backgroundColor: paletteColors.buttons_bg.secondary,
                            color: paletteColors.buttons_text.secondary,
                            borderColor: paletteColors.buttons_text.secondary,
                            textTransform: 'uppercase',
                            '&:hover': {
                                backgroundColor: paletteColors.buttons_bg.secondary,
                                color: paletteColors.buttons_text.secondary_hover,
                                borderColor: paletteColors.buttons_text.secondary_hover,
                            }
                        }),
                        ...(ownerState.color === 'info' && {
                            backgroundColor: paletteColors.buttons_bg.info,
                            color: paletteColors.buttons_text.info,
                            borderColor: paletteColors.buttons_text.info,
                            textTransform: 'uppercase',
                            '&:hover': {
                                backgroundColor: paletteColors.buttons_bg.info,
                                color: paletteColors.buttons_text.info_hover,
                                borderColor: paletteColors.buttons_text.info_hover,
                            }
                        }),
                        ...(ownerState.color === 'success' && {
                            backgroundColor: paletteColors.primary.main,
                            color: paletteColors.buttons_text.primary,
                            borderColor: paletteColors.buttons_text.primary,
                            '&:hover': {
                                backgroundColor: paletteColors.primary.main,
                                color: paletteColors.buttons_text.primary,
                            }
                        }),
                        fontSize: '16px',
                        borderRadius: '35px',
                        boxShadow: 'none',
                        fontStyle: 'normal',
                        padding: '5px 30px',
                        borderWidth: '2px',
                        borderStyle: 'solid',

                    })
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        '.MuiOutlinedInput-input': {
                            padding: '10px 14px'
                        }
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontSize: '16px',
                        fontWeight: '500'
                    }
                }
            },
            MuiLinearProgress: {
                styleOverrides: {
                    barColorPrimary: {
                        backgroundColor: paletteColors.primary.main
                    },
                    colorPrimary: {
                        backgroundColor: paletteColors.secondary.light
                    },
                    root: {
                        height: '2px'
                    }
                }
            },
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        color: paletteColors.primary.dark,
                        fontSize: '15px',
                        'p, div': {
                            fontSize: '15px',
                            color: paletteColors.primary.dark
                        }
                    }
                }
            }
        }
    });
}


