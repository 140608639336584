import { deleteGoogleCredentials } from '@/load_and_get_google_access_token';
import { Auth } from 'aws-amplify';
import { getCurrentBrand, getUserInfo, removeCustIdFromLS } from './useUserInfo';
import { GetOnboardinData } from '@/store/questionniareSlice';
import { redirectToNextPage } from '@/components/login';
import axiosObj from '@/store/axios';
import appleConfig from "@/config/apple/applelogin.json";
import { deleteFBKCredentialsFromLS } from './load_and_get_facebook_access_token';


const appleLocalStorageKey = "applels";

export const deleteAppleCredentialsFromLS = () => {
    localStorage.removeItem(appleLocalStorageKey);
}

export const setAppleCredentialsIntoLS = (tokenValue: any) => {
    localStorage.setItem(appleLocalStorageKey, JSON.stringify(tokenValue));
}

export const getAppleCredentialsFromLS = () => {
    try {
        const value = localStorage.getItem(appleLocalStorageKey);
        if (value) {
            return JSON.parse(value);
        }
        return ""
    } catch (e) {
        return ""
    }
}

export const signInWithApple = () => {
 // TBD : Need to implement the Apple login functionality through the Apple SDK
 //temparorily redirect to login page
    window.location.href = "/login?next="+encodeURIComponent(window.location.href);
}

export const updateRedirectUri = (redirectUri: string) => {
    const allowedHosts = ['www.nestingale.com', 'nestingale.com', 'qa.nestingale.com'];
    const hostname = typeof window !== 'undefined' ? window.location.hostname : null;
    const host = hostname && allowedHosts.includes(hostname) ? hostname : 'nestingale.com';
    console.log('updateRedirectUri::host---->', host);
    return redirectUri.replace("{host}", host);
}

// similar to load_and_get_google_access_token.ts and load_and_get_facebook_access_token.ts, need to creat load_and_get_apple_access_token.ts by moving the below responseApple into that file and following all the steps in the above mentioned files.
export const appleResponseCallback = (response: {
    error: any; authorization: { code: string; id_token: string }; identityToken: any;
    }, loginCallback?: Function) => {
    console.log("Apple button click callback : " + JSON.stringify(response));
    if (!response.error) {
        axiosObj({
            method: "POST",
            url: updateRedirectUri(appleConfig.redirect_uri),
            data: response.authorization
        })
            .then((response: any) => {
                console.log("Apple login response : " + JSON.stringify(response.data));
                getAppleAWSCredentials(response.data, loginCallback);
            })
            .catch((error) => {
                console.log("Apple login error : " + JSON.stringify(error));
            });
    }
}



export const getAppleAWSCredentials = (response: any, loginCallback?: Function) => {
    const { email, id, idToken, accessToken, expiresIn } = response;
    const date = new Date();
    const expires_at = expiresIn * 1000 + date.getTime();
    if (!id) {
        return;
    }
    const user = {
        name: email,
        email: email
    };

    let issuer = 'appleid.apple.com';
    Auth.federatedSignIn(issuer, { token: idToken, expires_at }, user)
        .then((credentials) => {
            //console.log(credentials);
            removeCustIdFromLS();
            deleteGoogleCredentials();
            deleteFBKCredentialsFromLS();
            setAppleCredentialsIntoLS({ ...user, authenticated : credentials.authenticated, expiresIn: credentials.expiration, sessionToken : credentials.sessionToken});

            (async () => {
                let userInfo;
                try {
                    userInfo = await getUserInfo("", true);
                } catch (e) {
                    console.log("Failed to save Apple email Address", e)
                }
                try {
                    if (loginCallback) {
                        loginCallback({type: "apple", userInfo: userInfo, status: "LOGIN_SUCCESS"});
                    } else {
                        //@ts-ignore
                        window.nestingale && (window.nestingale.triggeredRedirection = true);
                        redirectToNextPage();
                    }
                } catch (e) { }
            })();
            return credentials;
        }).catch((error) => {
            // Handle the error here
            console.log("Error for federatedSignIn Apple", error)
            alert("please retry the Apple authentication process");
        });
}