export const NESTINGALE = "nestingale";
export const AAMERICA = "aamerica";
export const FLEXSTEEL = "flexsteel";
export const HOMESTYLES = "homestyles";

export const LOADING = "loading";
export const SUCCESS = "success";
export const FAILED = "failed";
export const EMPTY = "empty";

