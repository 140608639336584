import React from "react";
import AppleLogin from "react-apple-login";
import appleConfig from "@/config/apple/applelogin.json";
import { appleResponseCallback, updateRedirectUri } from "@/utils/load_and_get_apple_access_token";

const AppleButton = ({appleLoginCallback}: {appleLoginCallback: any}) => {

    return (
        <div>
            <AppleLogin
                clientId={appleConfig.client_id}
                redirectURI={updateRedirectUri(appleConfig.redirect_uri)}
                responseMode="form_post"
                responseType="code id_token"
                scope="name email"
                usePopup={true}
                callback={appleLoginCallback}
                render={({ onClick, disabled }) => (
                    <div onClick={onClick}>
                            Continue with Apple
                    </div>
                )}     
            />
        </div>
    );  
}

export default AppleButton;