import nestingaleLogo from "../../assets/images/logo.svg";
import nestingaleLogoSmall from "../../assets/images/small_logo.png";
import styles from './logo.module.scss';
import React, { useEffect, useState } from 'react';
import { Stack } from "@mui/material";

const LogoComponent = (props) => {
    const { brand, footer, isBuilder } = props;
    const [isIOSWebKit, setIsIOSWebKit] = useState(false);
  
    useEffect(() => {
      if (typeof window !== 'undefined' && window.webkit) {
        setIsIOSWebKit(true);
      }
    }, []);
  
    return (
      <div className={`${isBuilder ? styles.builderLogo : styles.logo} ${footer ? styles.footer_logo : ''}`}>
        {!brand || brand.vendorName === "nestingale" ? (
          isIOSWebKit ? (
            <img
              src={isBuilder ? nestingaleLogoSmall.src : nestingaleLogo.src}
              alt="Nestingale Logo"
            />
          ) : (
            <a href="/">
              <img
                src={isBuilder ? nestingaleLogoSmall.src : nestingaleLogo.src}
                alt="Nestingale Logo"
              />
            </a>
          )
        ) : (
          <>
            <a href="/">
              <img
                src={nestingaleLogoSmall.src}
                className={styles["small-logo"]}
                alt="Nestingale Logo"
              />
            </a>
            <a href={"/" + brand.vendorName + "/rooms"} className={styles.brand_logo}>
              <img alt={brand.vendorDisplayName} src={brand.vendorLogo} />
            </a>
          </>
        )}
      </div>
    );
  };
  
  export default LogoComponent;