export default async function loadConfig(fileName: string): Promise<ConfigKeys> {
    return import(`./data/${fileName}.json`)
        .then(response => response.default)
        .catch(error => { });
}

export interface ConfigKeys {
    [key: string]: boolean | string | number
}

export interface BuilderContext {
    featureCatalog: ConfigKeys,
    config: ConfigKeys,
    env: string;
}