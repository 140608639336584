import { getFeatureCatalog } from "@/config/getCachedBuilderContext";

export const isFeatureAvailable = (featureName: string) => {
    const featureCatalog = getFeatureCatalog();
    // if (featureName === 'designTab') return !isDesigner(role);
    // if (featureName === 'furnitureTab' || featureName === 'wallsTab' ||
    //     featureName === 'lightingTab' || featureName === 'floorTab')
    //     return isDesigner(role);
    return featureCatalog[featureName];
}

export const isDesigner = (role: string) => {
    return role === 'designer';
}