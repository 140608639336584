const getConfig = ({domain="", protocol=""}) => {
  const config = {
    Auth: {
      region: "us-east-1",
      userPoolId: "us-east-1_9QPVGsLAP",
      userPoolWebClientId: "6b83igif6656o7a07jvisv18os",
      identityPoolId: "us-east-1:972add6b-c3f2-422d-901e-9b0bc50ee943",
      oauth: {
        domain: '//nestingaledev.auth.us-east-1.amazoncognito.com',
        scope: ['phone', 'email', 'openid', 'profile'],
        redirectSignIn: protocol+"://"+domain+"/",
        redirectSignOut: protocol+"://"+domain+"/",
        responseType: 'code'
      }
    }
  }
  return config;
}



export default getConfig