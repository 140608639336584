import { deleteGoogleCredentials } from '@/load_and_get_google_access_token';
import { Auth } from 'aws-amplify';
import { getCurrentBrand, getUserInfo, removeCustIdFromLS } from './useUserInfo';
import { GetOnboardinData } from '@/store/questionniareSlice';
import { redirectToNextPage } from '@/components/login';
import { deleteAppleCredentialsFromLS } from './load_and_get_apple_access_token';

const fbkLocalStorageKey = "fbkls";

export const deleteFBKCredentialsFromLS = () => {
    localStorage.removeItem(fbkLocalStorageKey);
}
  
export const setFBKCredentialsIntoLS = (tokenValue: any) => {
    localStorage.setItem(fbkLocalStorageKey,JSON.stringify(tokenValue));
}
  
export const getFBKCredentialsFromLS = () => {
    try{
        const value  = localStorage.getItem(fbkLocalStorageKey);
        if(value){
            return JSON.parse(value);
        }
        return ""
    }catch(e){
        return ""
    }
}

const fbAsyncInit = () => {
    // init the fb sdk client
    //@ts-ignore
    const fb = window.FB;
    fb.init({
      appId   : '780414300117322',
      cookie  : true,
      xfbml   : true,
      version : 'v18.0'
    });
  }

const initFB = () => {
    //@ts-ignore
    const fb = window.FB;
    console.log('FB SDK initialized');
}  

// load the sdk
export const createFBScript = () => {  
    //@ts-ignore
    window.fbAsyncInit = fbAsyncInit;
    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.onload = initFB;
    document.body.appendChild(script);
}



// To federated sign in from Facebook
export const signInWithFacebook = (router:any) => {
    //@ts-ignore
    const fb = window.FB;
    fb.getLoginStatus((response:any) => {
        if (response.status === 'connected') {
            getFBKAWSCredentials(response.authResponse, router,null);
        } else {
            fb.login((response:any) => {
                    if (!response || !response.authResponse) {
                        return;
                    }
                    getFBKAWSCredentials(response.authResponse, router, null);
                },
                {
                // the authorized scopes
                scope: 'public_profile,email'
                }
            );
        }
    });
}

 

export const getFBKAWSCredentials = (response:any, router: any, callback: any) => {
    const { accessToken, expiresIn=1 } = response;
    const date = new Date();
    const expires_at = expiresIn * 1000 + date.getTime();
    if (!accessToken) {
      return;
    }
    //@ts-ignore
    const fb = window.FB;
    fb.api('/me', { fields: 'name,email' },async (response:any) => {
      const user = {
        name: response.name,
        email: response.email
      };

    Auth.federatedSignIn('facebook', { token: accessToken, expires_at }, user)
        .then((credentials) => {
            console.log(credentials);
            removeCustIdFromLS();
            deleteGoogleCredentials();
            deleteAppleCredentialsFromLS();
            setFBKCredentialsIntoLS({...user,...credentials});

            (async ()=>{
                try{
                    const userInfo = await getUserInfo("",true);
                }catch(e){
                    console.log("Failed to save Google email Address",e)
                }
                try{
                    //@ts-ignore
                    window.nestingale && (window.nestingale.triggeredRedirection = true);
                    redirectToNextPage();
                }catch(e){}
            })();
            return credentials; 
        }).catch((error) => {
            // Handle the error here
            console.log("Error for federatedSignIn Facebook",error)
            alert("please retry the Facebook authentication process");
        });
    
    });
  }