import loadBuilderConfig from "./loadBuilderConfig";
import { BuilderContext } from "./loadConfig";

let builderContext: BuilderContext | undefined = undefined;

export default async function getBuilderContext(roleName: string) {
    //if (builderContext) return Promise.resolve(builderContext);
    const context = await loadBuilderConfig(roleName);
    builderContext = context;
    console.log('builder context loaded ;: ', builderContext);
    return Promise.resolve(builderContext);
}

export async function initBuilderContext(context: BuilderContext) {
    builderContext = context;
}

export const isBuilderContextReady = () => {
    return builderContext != null;
}
export function getFeatureCatalog() {
    if (!builderContext)
        throw 'Builder context not initialized';
    return builderContext.featureCatalog;
}

export function getBuilderConfig() {
    if (!builderContext)
        throw 'Builder context not initialized';
    return builderContext.config;
}

export function getEnv() {
    if (!builderContext)
        throw 'Builder context not initialized';
    return builderContext.env;
}