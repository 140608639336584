import React, { useState } from 'react';
import {Button } from "@mui/material";
import dynamic from 'next/dynamic'
//import { gtmClickTracking } from '@/utils';

interface RoomSelectorLayerButtonProps {
  buttonClassName?: string;
  color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'inherit' | 'default';
  children?: React.ReactNode; //| HTMLElement
  sRoom?: string;
  id?: string;
  openedFrom?: string;
  roomTypes?: any;
}

const RoomSelectorLayerButton: React.FC<RoomSelectorLayerButtonProps> = (
  {id, buttonClassName, children, color, sRoom, openedFrom, roomTypes}: RoomSelectorLayerButtonProps) => {

  const RoomSelectorLayer = dynamic(() => import('./RoomSelectorLayer'), {
    ssr: false,
  });

  
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    //gtmClickTracking({ clickName : 'room_selector_button_click'})
    console.log('RoomSelectorLayerButton opening Popup')
    setIsPopupOpen(true)
  };
  const closePopup = () => { 
    //gtmClickTracking({ clickName : 'room_selector_close_button_click'})
    console.log('RoomSelectorLayerButton closing Popup')
    setIsPopupOpen(false) 
  };

  return (
    <>
      {isPopupOpen && <RoomSelectorLayer sRoom={sRoom} closeLayer={closePopup} isPopupOpen={isPopupOpen} openedFrom={openedFrom} roomTypes={roomTypes} />}
      <Button id={id || 'room_selector_button'} color={ color == 'secondary' ? 'secondary' : 'primary' } sx={{whiteSpace: 'nowrap'}} onClick={openPopup} className={buttonClassName}>{children}</Button>
    </>
  );
};

export default RoomSelectorLayerButton;
