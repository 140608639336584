import { getEnv } from "@/config/getCachedBuilderContext";
import { BuilderProduct } from "@/store/builderSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const axiosObj = axios.create({
    baseURL: "https://d221a61rb87vel.cloudfront.net/v1"
});
const isValidNumber = (value: any) => {
    return typeof value === 'number' && !isNaN(value);
}

const isValidProduct = (product: BuilderProduct) => {
    const has3DModel = product.model3dUrl && product.model3dUrl.indexOf('https://') !== -1;
    const hasPrice = isValidNumber(product.price);
    const hasDimensions = product.productDimentions &&
        isValidNumber(product.productDimentions.depth) &&
        isValidNumber(product.productDimentions.height) &&
        isValidNumber(product.productDimentions.width);
    has3DModel && console.log('product :: ', product.model3dUrl, product.price, product.productDimentions);
    return has3DModel;
}
export const getValidProducts = (products: BuilderProduct[]) => {
    return products.filter(product => isValidProduct(product));
}

const loadedProductTypes: any = [];

export const updateLoadedProductTypes = (productType: string) => {
    loadedProductTypes.push(productType);
};

export const isProductTypeLoaded = (productType: string) => {
    return loadedProductTypes.includes(productType);
};

export const getBuilderProductData = async (
    config: { productType?: string, filterBy?: string, brand?: string }) => {
    let url = `/api/products?`
    if (config.productType && config.productType !== 'All_Products') {
        url += "&productType=" + config.productType
    }
    if (config.filterBy) { // filters=room:Bedrooms;productSubTypeName:doubledoorchest
        //url += `&filters=${config.filterBy}`;
    }
    if (config.brand && config.brand !== 'nestingale') {
        url += "&vendor=" + config.brand
    }
    return await axiosObj.get(url)
        .then((response) => {
            console.log("---- fetchProducts : URL : " + url + " responnse : " + JSON.stringify(response.data));
            return getValidProducts(response.data.products);
        }, (err) => {
            const errorObj = Object.assign({ description: "NEST_SPA::getBuilderProductData:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
            throw errorObj;
        });
}

export const getBuilderProductTypeData = async (config: {}) => {
    let url = `/api/allProductTypes?`;
    return await axiosObj.get(url)
        .then((response) => {
            //console.log("---- fetchProducts : URL : "+ url +" responnse : " + JSON.stringify(response.data));
            const res: any[] = response.data.prodTypeList;
            res.unshift({
                "imageUrl": "",
                "name": "All_Products",
                "displayName": "All Products",
                "productTypeId": "000001"
            });
            return res;
        }, (err) => {
            const errorObj = Object.assign({ description: "NEST_SPA::getBuilderProductTypeData:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
            throw errorObj;
        });
}


export const getRoomTypesData = async (config: {}) => {
    let url = `/api/roomTypes?status=active`;
    return await axiosObj.get(url)
        .then((response) => {
            const res: any[] = response.data.roomTypes;
            return res;
        }, (err) => {
            const errorObj = Object.assign({ description: "NEST_SPA::getBuilderProductTypeData:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
            throw errorObj;
        });
}


export const getBuilderProductSubTypeData = async (config: { productType: string }) => {
    let url = `/api/productSubTypes?productType=${config.productType}`;
    return await axiosObj.get(url)
        .then((response) => {
            //console.log("---- fetchProducts : URL : "+ url +" responnse : " + JSON.stringify(response.data));
            return response.data.prodSubTypes;
        }, (err) => {
            const errorObj = Object.assign({ description: "NEST_SPA::getBuilderProductTypeData:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
            throw errorObj;
        });
}
const getValidRelatedProducts = (products: any[]) => {
    const validProducts: BuilderProduct[] = [];
    products && products.forEach(obj => {
        const product = {
            productId: obj.productId,
            productName: obj.productName,
            productImage: obj.productMetaData.productImage,
            productDescription: obj.productDescription,
            productTypeName: obj.productTypeName,
            isDefault: 'false',
            model3dUrl: obj.productMetaData.model3dUrl,
            price: obj.productMetaData.price,
            comparePrice: obj.productMetaData.comparePrice,
            productDimentions: obj.productDimentions,
            productDesignImage: '',
            style: '',
            styleId: ''
        }
        isValidProduct(product) && validProducts.push(product);
    });
    return validProducts;

}

export const getSingleProduct = async (
    config: { productId?: string, includeRecommendations?: boolean }) => {
    let url = `/api/productDetails?productId=${config.productId}`;
    if (config.includeRecommendations) {
        url += `&includeRecommendations=${config.includeRecommendations}&filterBy3D=true`;
        if (getEnv() === 'qa')
            url += `&env=qa`;
    }
    return await axiosObj.get(url)
        .then((response) => {
            console.log("---- getSingleProduct : URL : " + url + " responnse : " + JSON.stringify(response.data));
            const productDetails = response.data;
            const similarProducts = getValidRelatedProducts(productDetails.similarProducts);
            const relatedProducts = getValidRelatedProducts(productDetails.relatedProducts);
            const product = {
                productId: productDetails.productId,
                productName: productDetails.productName,
                productImage: productDetails.productMetaData.productImage,
                productDescription: productDetails.productDescription,
                productTypeName: productDetails.productTypeName,
                isDefault: 'false',
                model3dUrl: productDetails.productMetaData.model3dUrl,
                price: productDetails.productMetaData.price,
                comparePrice: productDetails.productMetaData.comparePrice,
                productDimentions: productDetails.productDimentions,
                productDesignImage: '',
                style: '',
                styleId: '',
                similarProducts,
                relatedProducts
            }
            const validProducts = getValidProducts([product]);
            return [...validProducts, ...similarProducts, ...relatedProducts]
        }, (err) => {
            const errorObj = Object.assign({ description: "NEST_SPA::getBuilderProductData:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
            throw errorObj;
        });
}

export const loadBuilderProductsByType = createAsyncThunk(
    "builder/loadProductsByType",
    async (config: { productType: string, productId?: string, includeRecommendations?: boolean }, { rejectWithValue, getState }) => {
        try {
            let productData;
            if (config.productType === 'SingleProduct') {
                productData = await getSingleProduct({ productId: config.productId, includeRecommendations: config.includeRecommendations });
            } else {
                productData = await getBuilderProductData({ productType: config.productType });
            }

            console.log('valid products :: ', productData);
            const products = productData.map(product => {
                return {
                    ...product
                }
            });
            updateLoadedProductTypes(config.productType);
            return products;
        } catch (err) {
            console.log({ description: "NEST_SPA:Slices:loadProductsByType:Exception", errorCode: "NEST_SPA:BUILDER_PAGE:LOAD_PRODUCTS_BY_TYPE", err_desc: (err as Error).message, err_stack: (err as Error).stack });
            return rejectWithValue(err);
        }
    }
);