
import React, { useEffect, useState } from "react";

const useDeviceResolution = (minWidthSize: number = 1200) => {
   
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        const isDesktopMediaQuery = window.matchMedia(`(min-width: ${minWidthSize}px)`).matches;
        setIsDesktop(isDesktopMediaQuery);
      };
  
      handleResize(); // Check on initial component mount
  
      window.addEventListener('resize', handleResize);
      //return () => window.removeEventListener('resize.useDeviceResolution', handleResize);
    }, []);


    
    return isDesktop;
}

export default useDeviceResolution;




