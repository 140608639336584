"use client";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import ShareIcon from '@mui/icons-material/Share';
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import PinterestIcon from '@mui/icons-material/Pinterest';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { GetOnboardinData, updateStyleprofile } from "@/store/questionniareSlice";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { Divider } from "@mui/material";
import cssStyles from './style_profile.module.scss';
import { getGoogleCredentialsFromLS } from "../../load_and_get_google_access_token";
import ClientComponent from "../builder/client_component";
import { useRouter } from "next/router";
import useDeviceResolution from "@/hooks/use_device_resolution";
import { getFBKCredentialsFromLS } from "@/utils/load_and_get_facebook_access_token";
import { checkIfImageExists, getLoggedInEmailAddress, gtmPageOnloadTracking } from "@/utils";
import Layer from "../layer";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { API_BASE_URL, getUserInfo, setCustStyleProfileIdToLS } from "@/utils/useUserInfo";
import { useTheme } from '@mui/material/styles';
import { CircularProgress } from "@mui/material";
import UploadInstructions from "@/pages/[vendor]/uploadInstructions/[[...slug]]";
import UploadYourHomeInstructions from "../onboarding/components/upload_your_home_instructions";
import selectIcon from '../../assets/images/select-icon.png';
import bathRoomIcon from '../../assets/images/bathroom.png';
import kitchenIcon from '../../assets/images/kitchen-room.png';
import bedRoomIcon from '../../assets/images/bedroom.png';
import homeOfficeIcon from '../../assets/images/home-office.png';
import LivingRoomIcon from '../../assets/images/living-room.png';
import Dinning from '../../assets/images/dinning-room.png';
import roomScanImg from '../../assets/images/room_scan_quiz.png';
import roomQuizImg from '../../assets/images/room_quiz_img.png';
import shareScanRoomImg from '../../assets/images/share_scan_img.png'
import { navigateAndReplaceHistory, navigateWithHistory } from "@/utils";
import { getRoomTypeList } from "@/store/commonSlice"
import appleDownload from '../../assets/images/apple_app_download.png';
import { isIos } from "@/utils";
import RoomSelectorLayerButton from "../popups/RoomSelectoLayerButton";


let currentRoomObject: any = {};

function useLikesEffect(resetStyleProfileState: any, StyleProfileID: any, brand: string) {
  const [openLikesLayer, setLikesLayerOpen] = useState(false);


  const handleLikesLayerOpen = () => {
    resetStyleProfileState({
      updatedHowDidWedo: true
    })
    updateStyleprofile({
      styleProfileID: StyleProfileID,
      howDidWedo: "true",
      brand
    });
    setLikesLayerOpen(true);
  };

  const handleLikesLayerClose = () => {
    resetStyleProfileState({
      updatedHowDidWedo: true
    })
    updateStyleprofile({
      styleProfileID: StyleProfileID,
      howDidWedo: "false",
      brand
    });
    setLikesLayerOpen(false);
  };

  return { openLikesLayer, setLikesLayerOpen, handleLikesLayerOpen, handleLikesLayerClose }
}

interface ColorPaletteProps {
  title: string;
  description: string;
  images: string[];
}

interface Avatar {
  Image: string;
  Clap: string;
  Wave: string;
}

const useStyles = makeStyles((theme: any) => ({
  dialog: {
    maxWidth: 'none',
    margin: '0 auto'
  },
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    boxShadow: "unset",
    backgroundColor: "rgba(217, 217, 217, 0.13)"
  },
  textContainer: {
    flex: 1.9,
    marginRight: theme.spacing(2),
  },
  photoContainer: {
    flex: 1,
    marginLeft: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 30
  },
  photo: {
    width: "100%",
    maxWidth: 222,
    height: "auto",
    display: "block",
    cursor: "pointer"
  },
  button: {
    marginBottom: theme.spacing(1),
    background: "#FFFFFF",
    border: "1px solid #18A0FB",
    color: "#18A0FB",
    borderRadius: 6,
    lineHeight: "20px",
    fontSize: 16,
    letterSpacing: "-0.015em"
  },
}));

function IntroductionSectionWithAvatar({
  styleProfileData,
  updateStyleprofile,
  resetStyleProfileState,
  updatedAvatarImage,
  updateStyleprofileResponse,
  avatar,
  brand }: any) {

  const classes = useStyles();
  const triggeredCall = useRef<boolean>(false);
  const currentImageAvatarInfo = useRef<Avatar | null>(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [avatarWave, setAvatarWave] = useState<string | undefined>(undefined);



  useEffect(() => {
    if (styleProfileData && Object.keys(styleProfileData).length > 0) {
      //let myAvatar = styleProfileData.Persona.defaultAvatarImage;
      let myAvatar = styleProfileData.Persona.avatar[0];
      if (styleProfileData.myAvatarImage &&
        (typeof styleProfileData.myAvatarImage != 'string' || typeof styleProfileData.myAvatarImage != 'object')) {
        myAvatar = styleProfileData.myAvatarImage;
      } else {
        handleOverlayOpen();
      }
      setAvatarWave(myAvatar);
    }
  }, [styleProfileData])

  const handleOverlayOpen = () => {
    if (styleProfileData.IsEditMode) {
      //setIsOverlayOpen(true);
    }
  };


  useEffect(() => {
    if (updatedAvatarImage) {
      //fixed issue with resetting the boolean to tigger next selection of avatar
      resetStyleProfileState({ updatedAvatarImage: true })
      setAvatarWave(currentImageAvatarInfo.current?.Wave);
      setIsOverlayOpen(false);
    }
    triggeredCall.current = false;
  }, [updatedAvatarImage])

  const handleAvatarImgClick = (event: React.MouseEvent<HTMLImageElement>, eachAvatar: Avatar) => {
    if (triggeredCall.current || (!styleProfileData.IsEditMode)) {
      return;
    }
    const target = event.target as HTMLImageElement;
    triggeredCall.current = true;
    currentImageAvatarInfo.current = eachAvatar;
    updateStyleprofile({
      styleProfileID: styleProfileData.StyleProfileID,
      myAvatarImage: eachAvatar.Wave,
      brand
    });
  }

  const handleOverlayClose = () => {
    setIsOverlayOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={cssStyles.header_section}>
          {/* <div className={cssStyles.photoContainer}>

            <Link onClick={handleOverlayOpen}>
              {
                <div className={cssStyles.tear_shape}>
                  <video
                    key={avatarWave}
                    playsInline
                    className={classes.photo}
                    controls={false}
                    autoPlay muted loop>
                    <source src={avatarWave ? avatarWave : styleProfileData.Persona.avatar[0].Wave} type="video/mp4" />
                  </video>
                  <span className={cssStyles.video_hover}>Edit</span>
                </div>
              }
            </Link>

          </div> */}
          <div className={classes.textContainer}>

            <div className={cssStyles.header_title}>
              Welcome to your style profile,
              <span className={cssStyles.header_name}>{styleProfileData.CustomerFullName === ',' ? ' Home Enthusiast' : ' ' + styleProfileData.CustomerFullName}!</span>
            </div>

            <div className={cssStyles["header_info"]} dangerouslySetInnerHTML={{ __html: styleProfileData.Persona.personaDescription }}>
            </div>

          </div>
          <Dialog maxWidth="md" sx={{ "& .MuiDialog-paper": { borderRadius: "15px" }, "& .MuiModal-backdrop": { backdropFilter: 'blur(10px)' } }} className={`${classes.dialog} ${cssStyles["avatar-dialog-section"]}`} open={isOverlayOpen} onClose={handleOverlayClose}>
            <IconButton
              aria-label="close"
              onClick={handleOverlayClose}
              sx={{
                position: 'absolute',
                right: 13,
                top: 5,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon sx={{ width: '2em', height: '2em' }} />
            </IconButton>
            <h3 style={{ textAlign: 'center' }}>Choose Your Avatar</h3>
            <DialogContent style={{ margin: '0px 20px 20px  20px', padding: '0px' }} className={cssStyles["avatar-overlay"]}>
              {styleProfileData.Persona.avatar.map((eachAvatar: any) => (
                <img src={eachAvatar.Image} onClick={(e) => handleAvatarImgClick(e, eachAvatar)} alt="avatar image" key={eachAvatar.Image} style={{ height: "auto", width: "calc(33.3% - 10px)", margin: "0 5px", cursor: "pointer", borderRadius: "10px  " }} />
              ))}
            </DialogContent>
          </Dialog>
        </div>
      </Grid>
      <Grid item xs={12}>
        <hr className={cssStyles.divider} />
      </Grid>
    </Grid >

  );
}


const useColorSchemeStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: theme.spacing(2),
      paddingBottom: 0
    },
    title: {
      fontWeight: "bold",
      marginBottom: theme.spacing(2),
      fontSize: 15,
      lineHeight: "15px"
    },
    description: {
      marginBottom: theme.spacing(2),
      fontSize: 14,
      lineHeight: "14px"
    },
    colorsContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    colorBox: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    grid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "wrap",
      margin: theme.spacing(2),
    },
    image: {
      margin: theme.spacing(1),
      width: "auto",
      height: "auto",
      objectFit: "cover",
      borderRadius: theme.spacing(1),
    }
  })
);

function ColorPalette({ title, description, images }: ColorPaletteProps) {
  const classes = useColorSchemeStyles();

  const isDesktop = useDeviceResolution()

  const imageUrl = isDesktop ? images[0] : images[1];

  return (
    <div>
      <Typography variant="h6" className={cssStyles.title} style={{ marginTop: '20px' }}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.description}>
        {description}
      </Typography>
      {/* <div className={classes.colorsContainer}>
          {colors.map((color) => (
            <div
              key={color}
              className={classes.colorBox}
              style={{ backgroundColor: color }}
  
            />
          ))}
        </div> */}
      <div className={cssStyles.product_img}>
        <img key={imageUrl} src={imageUrl} alt="product Images" className={classes.image} />
      </div>
    </div>
  );
}


export const usedreamHomeImagesStyles = makeStyles((theme: any) => ({
  root: {
    display: "block",
    textAlign: "center",
    marginTop: "30px"
  },
  dreamHomeImagesSection: {
    display: "flex",
    justifyContent: "space-between"
  },
  imageContainer: {
    flex: 1,
    marginTop: 15,
    position: "relative"
  },
  desktopImageTileHolder: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "30px"
  },
  bgDiv: {
    position: "absolute",
    textTransform: "uppercase"
  },
  image: {
    objectFit: "contain",
    width: "90%"
  },
  innerBox: {
    flexBasis: "50%",
    flexGrow: 1

  },
  imageGrid: {
    marginTop: theme.spacing(1),
    textAlign: "center",
    position: "relative"
  },
  cardsHolder: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row"
  },
  customerInfoSection: {
    display: "flex",
    alignItems: "center"
  },
  photo: {
    width: "100px",
    height: "auto"
  }
}));

interface Images {
  [key: string]: string;
}
interface DreamHomeImagesStylesProps {
  title: string;
  images: Images;
  floorPlanImage: string;
  avatar?: any;
  customerFullName: string;
  brand: string;
  resetStyleProfileState: any;
  StyleProfileID: any;
  personaStyle: any;
  roomTypes: any;
  roomTypesMap: any;
  openLikesLayer: any;
  setLikesLayerOpen: any;
  handleLikesLayerOpen: any;
  handleLikesLayerClose: any;
}


interface DesignYourRoomLayerProps {
  classes?: any;
  avatar?: Avatar;
  customerFullName?: string;
  brand: string;
  resetStyleProfileState: any;
  StyleProfileID: any;
  personaStyle: any;
  handleDesignYourRoomLayerClose?: any;
  openLikesLayer: any;
  handleLikesLayerOpen: any;
}

interface MyCardProps {
  name?: string
  imgUrl: string;
  heading: string;
  description: string;
  imageNavURL?: string;
  ctaLabel?: string;
  ctaColor?: string;
  active: boolean;
  onClickHandler?: Function;
  setShowOverlay?: (arg0: boolean) => void;
}

export const MyCard = ({ name, imgUrl, heading, description, imageNavURL, ctaLabel, ctaColor, active, setShowOverlay, onClickHandler }: MyCardProps) => {
  const theme = useTheme(); // Access the theme object
  const navigate = useRouter();

  const onClickImageNavigationHandle = () => {
    onClickHandler ? onClickHandler(name) : navigateWithHistory(navigate, imageNavURL || "");
    if (setShowOverlay) {
      setShowOverlay(true)
    }
  }

  return (
    <Card className={`${cssStyles.card_box} ${active ? cssStyles.active : ''}`}>
      <CardContent style={{ padding: '0' }}>
        <Link id={name} onClick={onClickImageNavigationHandle} href="javascript:void(0)">
          <Typography variant="h5" color="primary"
            sx={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }} component="div" className={cssStyles.card_box_header}>
            {heading}
          </Typography>
          <div style={{ display: 'flex', textAlign: 'center', }}>
            {imgUrl !== '' &&
              <CardMedia
                component="img"
                alt="Sample Image"
                height="140"
                image={imgUrl}
                className={cssStyles.scan_images}
                sx={{margin: '10px !important'}}
              />
            }
            <div style={{paddingBottom: '10px'}}>
            <Typography variant="body2" color="text.secondary" sx={{
              padding: '20px',
              display: 'flex',
              alignItems: 'center',
              color: '#000'
            }}>
              {description}
            </Typography>
            {ctaLabel && <Button color={ ctaColor == 'secondary' ? 'secondary' : 'primary' } sx={{whiteSpace: 'nowrap', textTransform: 'capitalize !important'}}>{ctaLabel}</Button>}
            </div>
          </div>
        </Link>
      </CardContent>
    </Card>
  );
};



export function DesignYourRoomLayer({
  classes,
  avatar,
  customerFullName,
  brand,
  resetStyleProfileState,
  StyleProfileID,
  personaStyle,
  handleDesignYourRoomLayerClose,
  openLikesLayer,
  handleLikesLayerOpen
}: DesignYourRoomLayerProps) {
  const brandName = brand == "nestingale" ? "" : brand;
  const [showOverlay, setShowOverlay] = useState<any>(false);


  return <>
    <div className={classes.customerInfoSection}>

      {/* <div className={cssStyles.tear_shape_small}>
        <video
          key={avatar?.Wave}
          playsInline
          className={classes.photo}
          controls={false}
          autoPlay muted loop>
          <source src={avatar?.Wave ? avatar?.Wave : avatar?.Wave} type="video/mp4" />
        </video>
      </div> */}
      <div className={cssStyles.header_right}>
      
        <Typography variant="h6" className={cssStyles.lets_design}>Let&apos;s design your room!</Typography>
        { isIos() === true ? 
        <div className={cssStyles.instruction_tip}>
          <Typography variant="h3" sx={{ backgroundColor: 'buttons_text.info' }}>
            You&apos;re in complete control of your dream home. It&apos;s easy to get started!
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <div className={cssStyles.step_container_scan}>
                <p>
                  <span>1</span>Download the App
                </p>

              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={cssStyles.step_container_scan}>
                <p>
                  <span>2</span>Scan your room
                </p>

              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={cssStyles.step_container_scan}>
                <p>
                  <span>3</span>Unlock personalized designs
                </p>

              </div>
            </Grid>
          </Grid>
          <div className={cssStyles.app_download}>
            <a  id="style_profile.appstore_link_clicked" href="https://apps.apple.com/us/app/nestingale/id6461722582"><img src={appleDownload.src} /></a>
          </div>
        </div>
        :             
            <div className={cssStyles.instruction_tip}>
                <Typography variant="h2" sx={{padding: '10px'}}>
                    We are building exciting features for your phone. Meanwhile, please check out these immersive features on the desktop version of www.nestingale.com!
                </Typography>
            </div> }
        <div className={cssStyles.info}><strong>Congrats, {customerFullName === ',' ? ' Home Enthusiast' : ' ' + customerFullName}</strong> ! You are one step away from your dream space! Pick any of the options below to continue.</div>
      </div>
    </div>


    <div className={cssStyles.card_holder}>

      <MyCard
        heading={"Get my virtual room plan"}
        description={"Our app and smart visualization tools can turn your space into your dream home"}
        imgUrl={roomScanImg.src}
        setShowOverlay={setShowOverlay}
        active={true}
        imageNavURL={`${brandName ? "/" + brandName : ""}/uploadInstructions?roomType=` + currentRoomObject.roomType} />

      <MyCard
        heading={"Tell us more about your room"}
        description={"Not ready to design? Answer a few more question about your desired space to get recommendations."}
        imgUrl=""
        active={false}
        imageNavURL={`${brandName ? "/" + brandName : ""}/roomquiz?roomType=` + currentRoomObject.roomType} />
    </div>

    {/* {
      (showOverlay) ?
        <Layer
          open={showOverlay}
          onClose={() => {
            //setShowOverlay(false)
          }}
          Element={UploadYourHomeInstructions}
          dialogContentClassName={'upload-instructions-overlay'}
          dialogContentComponentProps={{ overlay: true }}
          dialogTitle={""}
          maxWidth={'lg'} /> : <></>
    } */}

  </>
}

export const STP_ROOM_TYPE_SEL_LS_KEY = 'stpRoomTypeSel';

function DreamHomeImagesStyles({
  title,
  images,
  floorPlanImage,
  customerFullName,
  brand,
  StyleProfileID,
  resetStyleProfileState,
  avatar,
  personaStyle,
  roomTypes,
  roomTypesMap,
  openLikesLayer,
  setLikesLayerOpen,
  handleLikesLayerOpen,
  handleLikesLayerClose
}: DreamHomeImagesStylesProps) {
  const classes = usedreamHomeImagesStyles();
  const isDesktop = useDeviceResolution(900);
  const [openDesignYourRoomLayer, setDesignYourRoomLayerOpen] = useState(false);
  const [renderDOM, setRenderDOM] = useState(new Date().getTime());
  const displayImagesOrder = useRef(['Living Room', 'Bed Room', 'Dining Room', 'Home Office', 'Kitchen', 'Bathroom']);

  const handleSelect = (roomTypeObject: any, image: string) => {
    try {
      if (roomTypeObject?.status == 'inactive') return;
      currentRoomObject = roomTypeObject;
      const key = roomTypeObject?.roomType;
      //remove the style profile room type selection
      localStorage.setItem(STP_ROOM_TYPE_SEL_LS_KEY, key);
      handleDesignYourRoomLayerOpen();
      setRenderDOM(new Date().getTime())
    } catch (e) { }
  };

  const handleDesignYourRoomLayerOpen = () => {
    setDesignYourRoomLayerOpen(true);
  };
  const handleDesignYourRoomLayerClose = () => {
    setDesignYourRoomLayerOpen(false);
  };


  const getSelectedRoomActive = (key: string) => {
    let activeClass = '';
    let userSelectedRoom = displayImagesOrder.current[0] || null;
    try {
      //pick previous seleted value on reload or choose Living Room
      userSelectedRoom = localStorage.getItem(STP_ROOM_TYPE_SEL_LS_KEY);// default 'Living Room'
    } catch (e) { }

    if ((userSelectedRoom == key)) {
      try {
        localStorage.setItem(STP_ROOM_TYPE_SEL_LS_KEY, userSelectedRoom);
      } catch (e) { }
      activeClass = 'active';
    }
    return activeClass;
  }

  return (
    <div>
      <div>
        <Typography variant="h6" className={cssStyles.title} sx={{ marginTop: '20px' }}>{title}</Typography>
        <Typography variant="h5" sx={{ marginBottom: '20px' }}>Select the room you&apos;d like begin designing:</Typography>
      </div>
      <div className={classes.dreamHomeImagesSection}>
        <div
          className={cssStyles.desktopImageTileHolder}>
          {
            displayImagesOrder.current.map((eachDisplayImage) => {
              let value = images[eachDisplayImage], key = eachDisplayImage;
              key = key.toLowerCase().replace(/[ ]/g, '');
              const roomTypeObject = roomTypes[key];
              const isRoomActive = roomTypeObject?.status == 'active';
              const buttonColor = isRoomActive ? "primary" : "secondary";
              return (
                <div key={key}  className={cssStyles.imageTile} >
                  <div style={{ position: 'relative' }}>
                    <img key={value} src={value} alt="selected" className={cssStyles[getSelectedRoomActive(key)]} />
                    <div className={cssStyles.cta_overlay}>
                    {isRoomActive ? <RoomSelectorLayerButton openedFrom="get_your_digital_room_plan" sRoom={key} color="secondary" roomTypes={roomTypesMap}>Start designing {roomTypeObject?.roomTypeDisplayName}</RoomSelectorLayerButton> : <Button variant="contained" color={buttonColor} className={cssStyles.design_room_btn} style={{ margin: '0px' }}>Coming Soon!</Button> }
                    
                      {/* <Button variant="contained" color={buttonColor} className={cssStyles.design_room_btn} style={{ margin: '0px' }}>
                        {isRoomActive ? 'Let\'s design your room!' : 'Coming Soon!'}
                      </Button> */}
                    </div>
                  </div>
                  <Typography variant="subtitle1" className="room-icon-text">
                    {key == 'livingroom' && <img className={cssStyles.icon} src={LivingRoomIcon.src} />}
                    {key == 'bedroom' && <img className={cssStyles.icon} src={bedRoomIcon.src} />}
                    {key == 'diningroom' && <img className={cssStyles.icon} src={Dinning.src} />}
                    {key == 'homeoffice' && <img className={cssStyles.icon} src={homeOfficeIcon.src} />}
                    {key == 'kitchen' && <img className={cssStyles.icon} src={kitchenIcon.src} />}
                    {key == 'bathroom' && <img className={cssStyles.icon} src={bathRoomIcon.src} />}

                    {roomTypeObject?.roomTypeDisplayName}
                  </Typography>
                </div>)
            })
          }
        </div>
      </div>

      {/* <Button color="secondary" className={cssStyles.design_room_btn} onClick={handleLikesLayerOpen}>
        Share your style profile
      </Button> */}

      {/* <Layer
        open={openDesignYourRoomLayer}
        onClose={handleDesignYourRoomLayerClose}
        dialogTitle=""
        Element={DesignYourRoomLayer}
        dialogContentClassName={"handle-design-your-roomLayer"}
        maxWidth="sm"
        dialogContentComponentProps={{
          openLikesLayer,
          handleLikesLayerOpen,
          handleDesignYourRoomLayerClose,
          classes, brand, customerFullName, avatar, StyleProfileID, resetStyleProfileState, personaStyle
        }} /> */}

      <ShareYourProfile
        openLikesLayer={openLikesLayer}
        onClose={() => {
          handleDesignYourRoomLayerClose && handleDesignYourRoomLayerClose()
        }}
        setLikesLayerOpen={setLikesLayerOpen}
        handleLikesLayerOpen={handleLikesLayerOpen}
        handleLikesLayerClose={handleLikesLayerClose}
        avatar={avatar}
        StyleProfileID={StyleProfileID}
        personaStyle={personaStyle}
      />
    </div>
  );
}


function DividerWithText({ text }: any) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '20px' }}>
      <Typography variant="body2" className={cssStyles['divider-text']}>
        {`${text}`}&nbsp;
      </Typography>
      <Divider style={{ flexGrow: 1, border: "1px solid #000000" }} />
    </div>
  );
}

const ShareButtonAndLayer = (props: any) => {
  const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);

  const handleWhatsAppShare = () => {
    const url = window.location.protocol + "//" + window.location.host + "/styleprofile/" + props.StyleProfileID;
    const imgUrl = props.avatar?.ShareableImage;
    // Text to be shared
    var text = `Check out this amazing link: ${url}`;

    // WhatsApp share URL
    var whatsappUrl = "https://api.whatsapp.com/send?text=" + encodeURIComponent(text);

    // Open WhatsApp in a new window
    window.open(whatsappUrl, "_blank");
  }

  const handleShareLayerClose = () => {
    props.setLikesLayerOpen(false);
  };

  const handleInstagramShare = () => {

  }

  const handleGlobalNavigatorShare = async () => {
    const shareData = {
      title: "Nestingale",
      text: "Check out this amazing link:",
      url: window.location.protocol + "//" + window.location.host + "/styleprofile/" + props.StyleProfileID,
    }
    await navigator.share(shareData);
  }

  const handleFacebookShare = () => {
    const url = window.location.protocol + "//" + window.location.host + "/styleprofile/" + props.StyleProfileID;
    const imgUrl = props.avatar?.ShareableImage;
    window.open('http://facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url) + '&picture=' + encodeURIComponent(imgUrl), '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
  }

  const handlePinterestShare = () => {
    const baseUrl = '//www.pinterest.com/pin/create/button/?';
    // format the url params
    var params = {
      url: window.location.protocol + "//" + window.location.host + "/styleprofile/" + props.StyleProfileID,
      media: props.avatar?.ShareableImage
    } as any;
    const paramsString = Object.keys(params).map(function (key) { return key + '=' + encodeURIComponent(params[key]); }).join('&');

    return baseUrl + paramsString;
  }
  //debugger

  const [scanImage, setScanImage] = useState("");

  useEffect(() => {
    checkIfImageExists(props.avatar?.ShareableImage, (exists: any) => {
      if (exists) {
        setScanImage(props.avatar?.ShareableImage);
      } else {
        setScanImage(shareScanRoomImg.src);
      }
    });
  }, [checkIfImageExists, setScanImage])



  return (<div>
    <IconButton
      aria-label="close"
      onClick={handleShareLayerClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8
      }}
    >
      <CloseIcon sx={{ fontSize: "20px" }} />
    </IconButton>


    <Typography component={'h1'} variant="body2" className='share-dialog-title'>
      {props.personaStyle}
    </Typography>
    <DividerWithText text={"@nestingale"} />
    <div className={cssStyles.share_section}>
      {scanImage !== '' &&
        <img
          src={scanImage}
          alt="Scan Image" />
      }

      <div style={{ padding: "15px" }}>
        <Typography variant="h3">
          Share your Style Identity
        </Typography>
        <div>
          <DialogActions sx={{ justifyContent: 'flex-start', padding: "0px" }}>
            <Grid container direction="column" alignItems="center" onClick={handleWhatsAppShare}>
              <Grid item>
                <IconButton sx={{ color: '#25D366' }}>
                  <WhatsAppIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Grid>
            </Grid>


            <Grid container direction="column" alignItems="center">
              <Grid item onClick={handleFacebookShare}>
                <IconButton sx={{ color: '#3B5998' }}>
                  <FacebookIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container direction="column" alignItems="center">
              <Grid item component={'a'} href={handlePinterestShare()} target="_blank">
                <IconButton sx={{ color: '#E60023' }}>
                  <PinterestIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogActions>
          <Grid className={cssStyles.show_in_mobile} container direction="column" alignItems="center">
            <Grid item onClick={handleGlobalNavigatorShare}>
              <IconButton>
                <ShareIcon sx={{ fontSize: "30px" }} />
              </IconButton>
            </Grid>
            <Grid item >
              <span style={{ marginLeft: '8px' }}>Share link</span>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>

  </div>)

}




// const NavigationSection = () => {
//   const navigateToHomePage = ()=>{

//   }
//   const continueToSelfService = ()=>{

//   }

//   return (<>

//     <Button variant="contained" color="primary" onClick={navigateToHomePage}
//       className={cssStyles["adjustLikesGotoHome"]}>
//         Go to My Home
//     </Button>
//     <Button onClick={continueToSelfService}
//       variant="outlined"
//       color="primary"
//       className={cssStyles["adjustLikesGotoHome"]}>
//         Continue to Self-service
//     </Button>
//   </>);
// }

const LikesAndShareSection = ({
  openLikesLayer,
  setLikesLayerOpen,
  handleLikesLayerOpen,
  handleLikesLayerClose
}: any) => {

  return (<>
    {/* <Typography className={cssStyles.hide_in_mobile} component="hr" sx={{ color: 'primary.main', width: "100%", border: "1px solid;" }} >
    </Typography> */}
    {/* <div className={cssStyles.share_style_profile}> */}
    {/* <Typography component="span" sx={{ cursor: 'pointer', textDecoration: "underline", fontSize: '14px' }} onClick={handleLikesLayerOpen} className={cssStyles["adjustLikes"]}>
        Make changes to your style profile
      </Typography> */}
    {/* <Typography component="span"
        sx={{ color: 'primary.main', cursor: 'pointer', textDecoration: "underline", fontSize: '14px' }}
        onClick={handleLikesLayerOpen}
        className={cssStyles["adjustLikes"]}>
        Share your style profile
      </Typography> */}
    {/* </div> */}

  </>);
}

interface ShareYourProfileProps {
  openLikesLayer: any;
  setLikesLayerOpen: any;
  handleLikesLayerOpen: any;
  handleLikesLayerClose: any;
  avatar: any;
  StyleProfileID: any;
  personaStyle: any;
  onClose?: any;
}

function ShareYourProfile({
  openLikesLayer,
  setLikesLayerOpen,
  handleLikesLayerOpen,
  handleLikesLayerClose,
  avatar,
  StyleProfileID,
  personaStyle,
  onClose
}: ShareYourProfileProps) {

  useEffect(() => {
    if (openLikesLayer && onClose) {
      onClose();
    }
  }, [openLikesLayer])
  return (<>
    <Dialog open={openLikesLayer} onClose={handleLikesLayerClose}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: '20%',
        paddingTop: '0vh',
        width: '80%',
        "& .MuiDialog-paper": {
          borderRadius: "15px",
        }
      }}>
      <DialogTitle className={cssStyles["share-background"]}>
        <IconButton
          aria-label="close"
          onClick={handleLikesLayerClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={cssStyles["share-background"]}>
        <div className={cssStyles.share_profile}>
          {/* <div className={cssStyles.tear_shape_small}>
            {
              avatar ? <video
                src={avatar.Clap}
                className={cssStyles["video-for-clap"]}
                controls={false}
                autoPlay muted></video> : null
            }
          </div> */}
          <Typography component={'h1'} variant="body2" sx={{ textTransform: 'none' }} className={cssStyles['share-dialog-title']}>
            Were glad you liked it! Share your profile for exclusive access to our 3D Room Scanning App!
          </Typography>
        </div>


        <ShareButtonAndLayer
          StyleProfileID={StyleProfileID}
          personaStyle={personaStyle}
          avatar={avatar}
          setLikesLayerOpen={setLikesLayerOpen} />
      </DialogContent>
    </Dialog>
  </>)
}

interface StyleProfileProps {
  brand: string;
  styleProfileID: string;
}

const StyleProfile: React.FC<StyleProfileProps> = (props: any) => {
  let { brand, styleProfileID, styleProfileDataForId, lsv, customerType } = props;
  let { saveCustomerStyleProfile,
    getStyleProfile,
    updateStyleprofile,
    resetStyleProfileState,
    updatedAvatarImage,
    updateStyleprofileResponse,
    updatedHowDidWedo,
    styleProfileData,
    postProfileData,
    profileData,
    customerFlowType } = GetOnboardinData();

  const brandName = brand == "nestingale" ? "" : brand;
  const {
    openLikesLayer,
    setLikesLayerOpen,
    handleLikesLayerOpen,
    handleLikesLayerClose } = useLikesEffect(resetStyleProfileState, styleProfileID, brandName)

  const [avatar, setAvatar] = useState(null);
  const [roomTypes, setRoomTypes] = useState([] as any);
  const [roomTypesMap, setRoomTypesMap] = useState({} as any);
  const [roomTypesMapLoaded, setRoomTypesMapLoaded] = useState(false);

  console.log('styleProfileData.Persona.dreamHomeImages', styleProfileData?.Persona?.dreamHomeImages);

  useEffect(() => {
    (async () => {
      const response = await getRoomTypeList({ env: 'prod' });
      setRoomTypesMap(response);
      setRoomTypesMapLoaded(true);
      if (response) {
        const roomTypeData: { [key: string]: any } = {}
        response.forEach((roomType: any) => {
          //hack - using lowercasedisplayname as the key
          //we need to do this to match the keys returned in
          //savecustomerstyleprofile API.
          const lowerCaseDisplayName = roomType.roomTypeDisplayName.replace(/[ ]/g, '').toLowerCase();
          roomTypeData[lowerCaseDisplayName] = roomType;
        });
        console.log('--------roomTypeData ------------', roomTypeData);
        setRoomTypes(roomTypeData);
      }
    })();
  }, []);

  if (styleProfileDataForId && (typeof window == "undefined")) {
    styleProfileData = styleProfileDataForId;
  }

  const navigate = useRouter();



  useLayoutEffect(() => {
    async function getStyleProfileInfo() {
      const userInfo = props.userInfo;
      console.log('-----------styleprofile userInfo-----------', userInfo);

      if (userInfo !== null) {
        if (userInfo.unauthorized) {
          return navigateAndReplaceHistory(navigate, `/login?next=${encodeURIComponent(window.location.href)}`);
        } else {
          const email = getLoggedInEmailAddress();
          const customerID = props.userInfo?.cId;
          console.log('-----------styleprofile props, email, customerID styleProfileID-----------'
            , props, email, customerID, styleProfileID);
          if (!styleProfileID) {
            const recheckStyleProfileId = true;
            const userInfoWithStyleProfileInfo = await getUserInfo(getLoggedInEmailAddress(), false, recheckStyleProfileId);
            styleProfileID = userInfoWithStyleProfileInfo?.styleProfileID;
            const fromStyleQuiz = localStorage.getItem("sqc") === "true";
            console.log('-----------styleprofile userInfoWithStyleProfileInfo-----------',
              userInfoWithStyleProfileInfo,
              styleProfileID,
              fromStyleQuiz);
            if (fromStyleQuiz) {
              gtmPageOnloadTracking({
                event: 'stylequiz_styleprofile_created'
              });
            }
            //either we found the style profileID or the user just completed their style quiz
            if (styleProfileID || fromStyleQuiz) {
              console.log('---styleprofile/index.tsx we are here!------ ');

              localStorage.removeItem("sqc");
              saveCustomerStyleProfile({
                "CustomerID": customerID,
                "EmailAddress": email, //guest user we dont pass email
                "questionnaireFlowType": customerType
              })
            } else {
              console.log('---styleprofile/index.tsx navigating to stylequiz------ ');
              return navigateAndReplaceHistory(navigate, `/stylequiz`);
            }
          }
        }
      }
    }
    getStyleProfileInfo();
  }, [props.userInfo])

  //  useLayoutEffect(() => {
  //    if (Object.keys(styleProfileData).length > 0 && props.userInfo?.cId) {
  //        console.log('-----------setting setCustStyleProfileIdToLS--------', props.userInfo.cId, styleProfileData.StyleProfileID);
  //        setCustStyleProfileIdToLS(props.userInfo.cId, styleProfileData.StyleProfileID);
  //    }
  //  }, [styleProfileData, props.userInfo])

  const navigateWithOutPreviousHistory = (router: any, path: any) => {
    router.push(path, undefined, { shallow: true, replace: true });
  };

  const navigateToDefaultQuestionnireFlow = () => {
    navigateWithOutPreviousHistory(navigate, "/stylequiz")
  }


  if (!styleProfileData || Object.keys(styleProfileData).length == 0) {
    return <><div className={cssStyles.loading}><CircularProgress /></div></>;
  }

  if (!roomTypesMapLoaded) return (<div>loading..</div>)
  return (
    <Box component="div" sx={{ borderWidth: '2px', borderColor: 'primary.main', borderStyle: 'solid', padding: "20px", borderRadius: "30px" }} id={cssStyles["style-profile-main"]}>


      <IntroductionSectionWithAvatar
        styleProfileData={styleProfileData}
        updateStyleprofile={updateStyleprofile}
        updatedHowDidWedo={updatedHowDidWedo}
        updatedAvatarImage={updatedAvatarImage}
        updateStyleprofileResponse={updateStyleprofileResponse}
        resetStyleProfileState={resetStyleProfileState}
        avatar={avatar}
        setAvatar={setAvatar}
        brand={brand} />

      <ColorPalette title="Your style vibe"
        images={styleProfileData.YourColorScheme.ProductImages} description={""} />
      <DreamHomeImagesStyles
        title="Now Let's Start Designing!"
        avatar={avatar}
        customerFullName={styleProfileData.CustomerFullName}
        images={styleProfileData.Persona.dreamHomeImages}
        floorPlanImage={styleProfileData.Persona.floorPlanImage}
        brand={brand}
        StyleProfileID={styleProfileData.StyleProfileID}
        resetStyleProfileState={resetStyleProfileState}
        personaStyle={styleProfileData.Persona.personaStyle}
        roomTypes={roomTypes}
        roomTypesMap={roomTypesMap}
        {...{
          openLikesLayer,
          setLikesLayerOpen,
          handleLikesLayerOpen,
          handleLikesLayerClose
        }}
      />
      {
        (!styleProfileData.IsEditMode) ?
          (<div>
            <div style={{ fontWeight: "bolder" }}>You Can Discover Your Own Style, Too!</div>
            <Button variant="contained" color="primary" onClick={navigateToDefaultQuestionnireFlow}
              className="adjustLikesGotoHome">
              Take the Style Questionnaire
            </Button>
          </div>) :
          (<LikesAndShareSection
            {...{
              openLikesLayer,
              setLikesLayerOpen,
              handleLikesLayerOpen,
              handleLikesLayerClose
            }} />)
      }
    </Box>)

}


export default ClientComponent(StyleProfile);