import { useSelector } from "react-redux";

const builderSlice = (state: any) => state.builder;
const useSelector_project = () => useSelector((state: any) => builderSlice(state).project)
const useSelector_productContent = () => useSelector((state: any) => builderSlice(state).productContent)
const useSelector_designContent = () => useSelector((state: any) => builderSlice(state).designContent)
const useSelector_undoStackPointer = () => useSelector((state: any) => builderSlice(state).undoStackPointer)
const useSelector_undoStack = () => useSelector((state: any) => builderSlice(state).undoStack)
const useSelector_ui = () => useSelector((state: any) => builderSlice(state).ui)
const useSelector_activeSelection = () => useSelector((state: any) => builderSlice(state).activeSelection)
const useSelector_roomModels = () => useSelector((state: any) => builderSlice(state).roomModels)
const useSelector_roomAssets = () => useSelector((state: any) => builderSlice(state).roomAssets)
const useSelector_roomTypes = () => useSelector((state: any) => builderSlice(state).roomTypes)
const useSelector_ResourceLoadStatus = () => useSelector((state: any) => builderSlice(state).resourceLoadStatus)
const useSelector_TwoDRoomLayout = () => useSelector((state: any) => builderSlice(state).twoDRoomLayout)
const useSelector_TwoDUi = () => useSelector((state: any) => builderSlice(state).twoDUi)

const BuilderSelct = {
    project: useSelector_project,
    productContent: useSelector_productContent,
    designContent: useSelector_designContent,
    undoStackPointer: useSelector_undoStackPointer,
    undoStack: useSelector_undoStack,
    ui: useSelector_ui,
    activeSelection: useSelector_activeSelection,
    roomModels: useSelector_roomModels,
    roomAssets: useSelector_roomAssets,
    roomTypes: useSelector_roomTypes,
    resourceLoadStatus: useSelector_ResourceLoadStatus,
    twoDRoomLayout: useSelector_TwoDRoomLayout,
    twoDUi: useSelector_TwoDUi
}
export default BuilderSelct;