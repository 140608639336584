
import { createSlice, createAsyncThunk, current, createAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import axiosObj from './axios';
import { prepareErrorObj } from '@/components/nesterror';
import axios from "axios";

const axiosObj2 = axios.create({
    baseURL: "https://d221a61rb87vel.cloudfront.net/v2"
});

interface CurrentRoomStyleData {
  name: string | null;
  styleName: string | null;
  roomIndex?: number | null;
  styles?: PanelItem[]
}

export enum BuilderType {
  SSB,
  IDB
}
export enum BuilderView {
  CANVAS,
  DESIGN
}

export interface ProductData {
  productSubTypeId?: string;
  productTypeId?: string;
  productId?: string;

  name?: string;
  displayName?: string;
  productName?: string;
  productTypeName?: string;
  productSubTypeName?: string;

  productImage?: string;
  imageUrl?: string;

  description: string;
}

export interface PanelItem {
  id: string;
  name?: string;
  image: string;
  subPanel?: Panel;
  selected?: boolean;
  displayName?: string;
}

export interface Panel {
  name: string;
  panelItems: PanelItem[];
  slides: Slide[];
}

export interface Slide {
  id: string;
  name?: string;
  displayName?: string;
  image: string;
  link?: string;
  selected?: boolean;
  products?: ProductData[];
}
export interface StateData {
  id?: string
  name?: string
  styleId?: string
  styleName?: string
  designId?: string
  designName?: string
  projectId?: string
  productId?: string

  rooms?: Panel
  styles?: PanelItem[]
  designs?: Slide[]
  designProducts?: ProductData[]

  currentRoomIndex?: number
  currentStyleIndex?: number
  currentDesignIndex?: number

  builderType?: BuilderType
  builderView?: BuilderView

  roomsCategory?: string
  error?: string
  designsData?: any
  productDetailData?: any
  productSubTypesData?: any
  productStylesData?: any
  productsData?: any,
  shopCategoryData?: any
  loading?: string,
  currentRequestId?: any
  vendorsData?: any
  displayName?: any
}
export const getInitialState = (data: any) => {
  return {

    id: data.id || null,
    roomName: data.roomName || null,
    styleId: data.styleId || null,
    styleName: data.styleName || null,
    designId: data.designId || null,
    designName: data.designName || null,
    projectId: data.projectId || null,
    productId: data.productId || null,

    builderType: BuilderType.SSB,
    builderView: BuilderView.DESIGN,

    rooms: data.rooms || null,
    styles: data.styles || null,
    designs: data.designs || null,
    designProducts: data.designProducts || null,

    currentRoomIndex: 0,
    currentStyleIndex: 0,
    currentDesignIndex: 0,

    roomsCategory: {},
    error: "",
    designsData: data.designsData || null,
    productDetailData: data.productDetailData || null,
    productSubTypesData: data.productSubTypesData || null,
    productStylesData: data.productStylesData || null,
    productsData: data.productsData || null,
    shopCategoryData: data.shopCategoryData || null,
    roomsLoading: data.roosLoading || "loading",
    roomDesignsLoading: data.roosLoading || "loading",
    currentRequestId: data.currentRequestId || null,
    vendorsData: data.vendorsData || null,
    displayName: data.displayName || ""

  }
}

export const getRooms = async (config: { brand?: string , env?: string}) => {
  const headers = {
    'content-type': "application/json"
  }
  let url = "/api/rooms";
  if (config.brand && config.brand !== 'nestingale') {
    url += "?vendor=" + config.brand
  }
  if (config.env && config.env !== 'prod') {
    url += (url.includes("?") ? "&env=" : "?env=") + config.env
  }
  //console.log("-------------- getRooms url=", url)
  return await axiosObj2.get(url, headers as any)
    .then((response) => {
      return {
        name: "Rooms",
        panelItems: response.data?.rooms?.map((room: any, roomIndex: number) => {
          if (!room.id) return;
          return {
            id: room.id,
            name: room.name,
            image: room.roomImage || null,
            selected: false,
            displayName: room.displayName || null,
            subPanel: {
              name: "Styles",
              panelItems: room.supportedStyles?.map((style: any, styleIndex: number) => {
                if (!style.styleId) return;
                return {
                  id: style.styleId,
                  name: style.style,
                  displayName: style.styleDisplayName,
                  image: style.styleImage || null,
                  link: null,
                  selected: false,
                } as PanelItem
              })
            } as Panel
          } as PanelItem;
        }),
        slides: []
      } as Panel;
    }, (err) => {
      const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchCarousalRoomsList:Exception", errorCode: "NEST_SPA:HOME_PAGE" }, prepareErrorObj(err))
      console.log(errorObj);
      throw errorObj;
    });
}

export const getDesignById = async (config: { designId: any }) => {
  const headers = {
    'content-type': "application/json"
  }
   console.log('--------------homeslice---------------', config);
  const url = "/api/designs?designId=" + config.designId;
  return await axiosObj2.get(url, headers as any)
    .then((response) => {
      const data =  response?.data;
      let result: any = {};
      result.roomType = data?.roomName;
      result.vendor = data?.vendorName;
      const design = data?.designs?.[0];
      if (typeof design === 'object' && design !== null && Object.keys(design).length > 0) {
        result = { ...result, ...design };
      }
      console.log('--------------homeslice---------------', config, response, response?.data?.designs, result)
      return result;
    }, (err) => {
      const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchRoomDesignsList:Exception", errorCode: "NEST_SPA:HOME_PAGE" }, prepareErrorObj(err))
      console.log(errorObj);
      throw errorObj;
    });
}

export const getRoomDesigns = async (config: { room: string, style: string, brand?: string, env?: string }) => {
  const headers = {
    'content-type': "application/json"
  }  
  let url = "/api/designs?room=" + encodeURIComponent(config.room) + "&style=" + encodeURIComponent(config.style);
  if (config.brand && config.brand !== 'nestingale') {
    url += "&vendor=" + config.brand
  }
  if (config.env && config.env !== 'prod') {
    url += (url.includes("?") ? "&env=" : "?env=") + config.env
  }
  return await axiosObj2.get(url, headers as any)
    .then((response) => {
      return response.data.designs?.map((design: any, index: number) => {
          return {
            id: design.designId,
            name: design.designName,
            image: design.designImage || null,
            selected: (index === 0),
            products: design.products,
            isPersonalizable: design.isPersonalizable
          } as Slide;
        });
    }, (err) => {
      const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchRoomDesignsList:Exception", errorCode: "NEST_SPA:HOME_PAGE" }, prepareErrorObj(err))
      console.log(errorObj);
      throw errorObj;
    });
}

export const fetchCarousalRoomsList = createAsyncThunk(
  "home/fetchCarousalRoomsListRes",
  async (config: { brand?: string }, { rejectWithValue }) => {
    try {
      const headers = {
        'content-type': "application/json"
      }
      let url = "/api/rooms";
      if (config.brand && config.brand !== 'nestingale') {
        url += "?vendor=" + config.brand
      }
      const carousalRoomsListData = await axiosObj.get(url, headers as any)
        .then((response) => {
          return response.data
        }, (err) => {
          const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchCarousalRoomsList:Exception", errorCode: "NEST_SPA:HOME_PAGE" }, err)
          console.log(errorObj);
          throw errorObj;
        });

      //console.log("carousalRoomsListData=", carousalRoomsListData)
      return carousalRoomsListData;
    } catch (err) {
      console.log({ description: "NEST_SPA:Slices:fetchCarousalRoomsList:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack });
      return rejectWithValue(err);
    }
  }
);

export const fetchRoomDesignsList = createAsyncThunk(
  "home/fetchRoomDesignsListRes",
  async (config: { room: string, style: string, brand?: string }, { rejectWithValue }) => {
    try {
      let url = "/api/designs?room=" + encodeURIComponent(config.room) + "&style=" + encodeURIComponent(config.style);
      if (config.brand && config.brand !== 'nestingale') {
        url += "&vendor=" + config.brand
      }
      const RoomDesignsListData = await axiosObj.get(url)
        .then((response) => {
          return response.data
        }, (err) => {
          const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchRoomDesignsList:Exception", errorCode: "NEST_SPA:HOME_PAGE" }, err)
          console.log(errorObj);
          throw errorObj;
        });

      //console.log("RoomDesignsListData=", RoomDesignsListData)
      return RoomDesignsListData;
    } catch (err) {
      console.log({ description: "NEST_SPA:Slices:fetchCarousalRoomsList:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack });
      return rejectWithValue(err);
    }
  }
);

const setRoomState = (state: any, index: number, reset: boolean = false) => {
  const currRoom = state.rooms?.panelItems[index];
  if (currRoom) {
    state.currentRoomIndex = index;
    state.name = currRoom.name || null;
    state.id = currRoom.id;
    state.displayName = currRoom.displayName;
    currRoom.selected = true;
    state.styles = currRoom?.subPanel?.panelItems;
    //TODO : need to fix this to show prev room style selection
    if (reset) {
      let currStyleIndex = 0;
      state.styles && state.styles?.map((style: PanelItem, index: number) => {
        if (style.selected === true) {
          currStyleIndex = index;
        }
      });
      setStyleState(state, currStyleIndex)
    }
  }
}
const setStyleState = (state: any, index: number) => {
  if (state.currentStyleIndex !== index) {
    state.styles && (state.styles[state.currentStyleIndex].selected = false);
  }
  const currStyle = state.styles && state.styles[index];
  state.currentStyleIndex = index;
  state.styleId = currStyle?.id || null;
  state.styleName = currStyle?.name || null;
  currStyle && (currStyle.selected = true);
}

const HomePageSlice = createSlice({
  name: "home",
  initialState: getInitialState({}),
  reducers: {
    roomSelected: (state, action) => {
      setRoomState(state, action.payload.roomIndex, true)
    },
    styleSelected: (state, action) => {
      //console.log(" --home-- styleSelected dispatchAction received ....." + JSON.stringify(action.payload))
      setStyleState(state, action.payload.styleIndex)
      state.builderView = BuilderView.DESIGN;
    },
    designSelected: (state, action) => {
      //console.log(" --home-- designSelected dispatchAction received ....." + JSON.stringify(action.payload))
      state.currentDesignIndex = action.payload.designIndex || 0;
      state.designId = action.payload.designId || null;
      state.designName = state.designs?.[state.currentDesignIndex]?.name || null;
      state.designProducts = action.payload.designProducts || null
      state.designProducts = action.payload.designProducts || null
      state.builderView = BuilderView.DESIGN;
    },
    setRoomNStyleNames: (state, action) => {
      console.log("--home-- setRoomNStyleNames : " + JSON.stringify(action.payload));
      if (action.payload.roomName) {
        state.roomName = action.payload.roomName;
        if (action.payload.styleName) {
          state.styleName = action.payload.styleName;
        }
      }

      if (state.roomName) {
        state.rooms?.panelItems.map((room: any, roomIndex: number) => {
          if (state.roomName === room.name) {
            setRoomState(state, roomIndex, state.styleName ? false : true);
            if (state.styleName) {
              room.subPanel?.panelItems.map((style: any, styleIndex: number) => {
                if (state.styleName && state.styleName.toLowerCase() === style.name?.toLowerCase()) {
                  setStyleState(state, styleIndex);
                }
              })
            }
          }
        })
      } else {
        setRoomState(state, 0, true);
      }

    }

  },
  extraReducers: (builder) => {

    builder.addCase(HYDRATE, (state, action: any) => {
      //console.log("-----------> HOME SLICE HYDRATE : <------------ ");
      //console.log("HYDRATE STATE : -------->  : " + JSON.stringify(state))
      //console.log("HYDRATE action.payload.reducer : -------->  : " + JSON.stringify(action.payload.reducer))
      return {
        ...state,
        ...action.payload.home,
      };
    })

    builder.addCase(fetchCarousalRoomsList.fulfilled, (state, { meta, payload, type }) => {
      //if (meta.requestId === state.currentRequestId.requestId) {
      //state.rooms = payload.rooms;
      state.roomsLoading = "success";
      state.currentRequestId = "";
      //console.log(type);
      //console.log("state", state);
      //console.log(`--home-- preparing styles panel data for id: ${state.id}, styleId: ${state.styleId}, roomsListDataRes: ${JSON.stringify(state.rooms)}` );
      if (payload?.rooms) {
        state.rooms = {
          name: "Rooms",
          panelItems: payload?.rooms?.map((room: any, roomIndex: number) => {
            if (!room.id) return;
            return {
              id: room.id,
              name: room.name,
              image: room.roomImage || null,
              selected: false,
              displayName: room.displayName || null,
              subPanel: {
                name: "Styles",
                panelItems: room.supportedStyles?.map((style: any, styleIndex: number) => {
                  if (!style.styleId) return;
                  return {
                    id: style.styleId,
                    name: style.style,
                    image: style.styleImage || null,
                    link: null,
                    selected: false,
                  } as PanelItem
                })
              } as Panel
            } as PanelItem;
          }),
          slides: []
        } as Panel;
      } else {
        //console.log("-----------NO ROOMS-STYLES IN PAYLOAD ------");
      }
    })
    builder.addCase(fetchCarousalRoomsList.pending, (state, { meta }) => {
      //console.log(current(state))
      //state.currentRequestId = meta;
      state.roomsLoading = "pending";
    })
    builder.addCase(fetchCarousalRoomsList.rejected, (state, { meta, payload, error }) => {
      //if (meta.requestId === state.currentRequestId.requestId) {
      //state.currentRequestId = meta;
      state.roomsLoading = "failed";
      //state.rooms = payload;
      state.error = error as string;
      //}
    })

    builder.addCase(fetchRoomDesignsList.fulfilled, (state, { meta, payload, type }) => {
      //console.log("----- home -- fetchRoomDesignsList.fulfilled------- ")
      state.designsData = payload;
      state.roomDesignsLoading = "success";
      state.currentRequestId = "";
      //console.log(current(state))
      if (payload?.designs) {
        state.designs = payload.designs?.map((design: any, index: number) => {
          if (state.designId == design.designId) {
            state.currentDesignIndex = index;
            state.designName = design.designName;
          }
          return {
            id: design.designId,
            name: design.designName,
            image: design.designImage || null,
            selected: (design.designId === state.designId || index === 0),
            products: design.products
          } as Slide;
        });
        //state.rooms?.slides = state.designs;
        state.designProducts = state.designs?.[state.currentDesignIndex]?.products;
      } else {
        //console.log("-----------NO DESIGNS IN PAYLOAD ------");
      }

    })
    builder.addCase(fetchRoomDesignsList.pending, (state, { meta }) => {

          //state.currentRequestId = meta;
          state.roomDesignsLoading = "pending";
      })
      builder.addCase(fetchRoomDesignsList.rejected, (state, { meta, payload, error }) => {
          //if (meta.requestId === state.currentRequestId.requestId) {
              //state.currentRequestId = meta;
              state.roomDesignsLoading = "failed";
              state.designsData = payload;
              state.error = error as string;
          //}
      })        
  }
});

export const { actions, reducer } = HomePageSlice;
export default reducer;