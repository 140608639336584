
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import axiosObj from './axios';
import { LOADING } from './constants';
import { prepareErrorObj } from '@/components/nesterror';

export interface Address {
  address1: string;
  address2: string;
  address3: string;
}
export interface Vendor {
  vendorId: string;
  vendorName: string;
  vendorDisplayName?: string;
  vendorLogo?: string;
  vendorAddress?: Address;
  vendorYoutubeLink?: string;
  hasDeals?: boolean;
  dealsBanner?: string;
  landingPage?: string;
}


const getInitialState = (data: any) => {
  return {
    error: "",
    loading: LOADING,
    currentRequestId: data.currentRequestId || null,
    brand: data.brand || null,
    vendorsData: data.vendorsData || {},
    shopCategoryData: data.shopCategoryData || {},
    categoriesLoading: data.categoriesLoading || "loading",
    vendorsLoading: data.vendorsLoading || "loading",
  }
}

export const shopCategoryData = (state: any) => state.home.shopCategoryData;
export const getDesignData = (state: any) => state.home.designsData.designs;
export const getSlidesData = (state: any) => state.slidesData;
export const vendorsData = (state: any) => state.home.vendorsData;


export const getRoomTypeList = async (config: { brand?: string, env?: string, status?: string }) => {
  const headers = {
    'content-type': "application/json"
  }
  let url = (config.status) ? "/api/roomTypes?status="+config.status : "/api/roomTypes?";
  if (config.brand && config.brand !== 'nestingale') {
    url += "&vendor=" + config.brand
  }
  if (config.env && config.env !== 'prod') {
    url += "&env=" + config.env
  }
  //console.log("-------------- getRoomTypes url=", url)
  return await axiosObj.get(url, headers as any)
    .then((response) => {
      return response.data.roomTypes;
    }, (err) => {
      const errorObj = Object.assign({ description: "NEST_SPA:Slices:getRoomTypeList:Exception", errorCode: "NEST_SPA:HOME_PAGE" }, prepareErrorObj(err))
      console.log(errorObj);
      throw errorObj;
    });
}

export const getCatagoryList = async (config: { brand?: string, env?: string }) => {
    const headers = {
      'content-type': "application/json"
    }
    let url = "/api/allProductTypes";
    if (config.brand && config.brand !== 'nestingale') {
      url += "?vendor=" + config.brand
    }
    if (config.env && config.env !== 'prod') {
      url += (url.includes("?") ? "&env=" : "?env=") + config.env
    }
    //console.log("-------------- getCatagoryList url=", url)
    return await axiosObj.get(url, headers as any)
      .then((response) => {
        return response.data
      }, (err) => {
        const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchCatagoryList:Exception", errorCode: "NEST_SPA:HOME_PAGE" }, prepareErrorObj(err))
        console.log(errorObj);
        throw errorObj;
      });
}

export const getVendors = async (env: string = 'prod') => {
    let url = `/api/vendors`;
    if (env && env !== 'prod') {
      url += "?env=" + env
    }
    //console.log("-------------- getVendors url=", url)
    return await axiosObj.get(url)
      .then((response) => {
        if(!response || !response.data) throw new Error("No response from server for getVendors");
        return response.data
      }, (err) => {
        const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchVendors:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE" }, prepareErrorObj(err))
        console.log(errorObj);
        throw errorObj;
      });
}

export const fetchCatagoryList = createAsyncThunk(
  "common/fetchCatagoryListRes",
  async (config: { brand?: string }, { rejectWithValue }) => {
    try {
      const headers = {
        'content-type': "application/json"
      }
      let url = "/api/allProductTypes";
      if (config.brand && config.brand !== 'nestingale') {
        url += "?vendor=" + config.brand
      }
      const carousalCatagoryListData = await axiosObj.get(url, headers as any)
        .then((response) => {
          return response.data
        }, (err) => {
          const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchCatagoryList:Exception", errorCode: "NEST_SPA:HOME_PAGE" }, prepareErrorObj(err))
          console.log(errorObj);
          throw errorObj;
        });

      //console.log("carousalCatagoryListData=", carousalCatagoryListData)
      return carousalCatagoryListData;
    } catch (err) {
      console.log({ description: "NEST_SPA:Slices:fetchCatagoryList:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack });
      return rejectWithValue(err);
    }
  }
);

export const fetchVendorsDetails = createAsyncThunk(
  "products/vendorsDetailsRes",
  async (config, { rejectWithValue }) => {
    try {
      let url = `/api/vendors`;
      const vendorsData = await axiosObj.get(url)
        .then((response) => {
          return response.data
        }, (err) => {
          const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchVendors:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE" }, err)
          console.log(errorObj);
          throw errorObj;
          //return vendorsApiResponseJson;
        });
      //console.log("-----------------  vendorsData=", vendorsData)
      return vendorsData;
    } catch (err) {
      console.log({ description: "NEST_SPA:Slices:fetchvendors:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack });
      return rejectWithValue(err);
    }
  }
);


export const fetchProductsList = createAsyncThunk(
  "common/fetchProductsListRes",
  async (config: { brand?: string }, { rejectWithValue }) => {
    try {
      const headers = {
        'content-type': "application/json"
      }
      let url = "/api/allProductTypes";
      if (config.brand && config.brand !== 'nestingale') {
        url += "&vendor=" + config.brand
      }
      const carousalCatagoryListData = await axiosObj.get(url, headers as any)
        .then((response) => {
          return response.data
        }, (err: any) => {
          const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchCatagoryList:Exception", errorCode: "NEST_SPA:HOME_PAGE" }, err)
          console.log(errorObj);
          throw errorObj;
        });

      //console.log("carousalCatagoryListData=", carousalCatagoryListData)
      return carousalCatagoryListData;
    } catch (err) {
      console.log({ description: "NEST_SPA:Slices:fetchCatagoryList:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack });
      return rejectWithValue(err);
    }
  }
);

const commonSlice = createSlice({
  name: "common",
  initialState: getInitialState({}),
  reducers: {
    setVendorBrandName: (state, action) => {
      //console.log(" -- productCategoryPageSlice -- brand " + JSON.stringify(action.payload))
      state.brand = action.payload;
    }
  },
  extraReducers: (builder) => {

    builder.addCase(HYDRATE, (state, action:any) => {
      //console.log("-----------> COMMON SLICE HYDRATE : <------------ " );
      //console.log("HYDRATE STATE : -------->  : " + JSON.stringify(state))
      //console.log("HYDRATE action.payload.reducer : -------->  : " + JSON.stringify(action.payload.reducer))
      return {
        ...state,
        ...action.payload.common,
      };
    } )
    builder.addCase(fetchCatagoryList.fulfilled, (state, { meta, payload, type }) => {
      //if (meta.requestId === state.currentRequestId.requestId) {
      state.shopCategoryData = payload;
      state.categoriesLoading = "success";
      state.currentRequestId = "";
      //console.log(current(state))
    })
    builder.addCase(fetchCatagoryList.rejected, (state, { meta, payload, error }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = meta;
        state.categoriesLoading = "failed";
        state.shopCategoryData = payload;
        state.error = error as string;
      }
    })
    builder.addCase(fetchVendorsDetails.fulfilled, (state, { meta, payload, type }) => {
      state.vendorsData = payload;
      state.vendorsLoading = "success";
      state.currentRequestId = "";
      //console.log(current(state.productDetailData))
    })
    builder.addCase(fetchVendorsDetails.rejected, (state, { meta, payload, error }) => {
      state.currentRequestId = meta;
      state.vendorsLoading = "failed";
      state.vendorsData = payload;
      state.error = error as string;
    })

  }
});

export const { actions, reducer } = commonSlice;
export default reducer;