import { Project } from "@/store/builderSlice";
import { getUserId } from "@/utils/builder/userUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const axiosObj = axios.create({
    baseURL: "https://d221a61rb87vel.cloudfront.net/v1"
});
export const saveProject = createAsyncThunk(
    "builder/saveProject",
    async (config: {}, { rejectWithValue, getState }) => {
        try {
            let url = `api/projects`;
            let method = 'post';
            const methodHandlers = {
                post: axiosObj.post,
                put: axiosObj.put,
            };
            const projectJson: Project = Object.assign({}, (getState() as any).builder.project);
            const projectId = projectJson.projectId;
            if (projectId) {
                url += `/${projectId}`;
                method = 'put';
                delete projectJson.projectId;
            }

            //@ts-ignore
            const result = await methodHandlers[method](url, projectJson,
                {
                    headers: {
                        'Customer-Id': getUserId()
                    }
                })
                .then((response: any) => {
                    return response.data
                }, (err: any) => {
                    const errorObj = Object.assign({ description: "NEST_SPA:Slices:saveProject:Exception", errorCode: "NEST_SPA:BUILDER_PAGE:SAVE_PROJECT" }, err)
                    console.log(errorObj);
                    throw errorObj;
                });
            console.log("Project saved :: ", result);
            return projectId || result.projectId;
        } catch (err) {
            console.log({ description: "NEST_SPA:Slices:saveProject:Exception", errorCode: "NEST_SPA:BUILDER_PAGE:SAVE_PROJECT", err_desc: (err as Error).message, err_stack: (err as Error).stack });
            return rejectWithValue(err);
        }
    }
);

export const loadProject = async (config: { projectId: string }) => {
    let url = `api/projects/${config.projectId}`;
    return await axiosObj.get(url, {
        headers: {
            'Customer-Id': getUserId()
        }
    })
        .then((response) => {
            return response.data;
        }, (err) => {
            const errorObj = Object.assign({ description: "NEST_SPA::getBuilderProductTypeData:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
            throw errorObj;
        });
}