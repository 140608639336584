import { TwoDMesh, TwoDVertex } from "@/store/builderSlice";

export const getTwoDRoomLayoutVertices = (width: number, height: number) => {
    return [
        {
            x: 0,
            y: 0
        },
        {
            x: width * 1,
            y: 0
        },
        {
            x: width * 1,
            y: height * 1
        },
        {
            x: 0,
            y: height * 1
        }
    ]
}
const getTwoDMeshRotation = (startVertex: TwoDVertex, endVertex: TwoDVertex) => {
    const m = (endVertex.y - startVertex.y) / (endVertex.x - startVertex.x);
    const angleInRadians = Math.atan(m);
    const angleInDegrees = (angleInRadians * 180) / Math.PI;
    return angleInDegrees;
}

const getTwoDMeshWidth = (startVertex: TwoDVertex, endVertex: TwoDVertex) => {
    return Math.sqrt(Math.pow(endVertex.x - startVertex.x, 2) + Math.pow(endVertex.y - startVertex.y, 2));
}

export const getWallsFromVertices = (vertices: TwoDVertex[]) => {
    const twoDMeshes: TwoDMesh[] = [];
    for (let i = 0; i < vertices.length; i++) {
        const endVertexIndex = i === vertices.length - 1 ? 0 : i + 1;
        const wallMesh = {
            id: `wall${i}`,
            type: 'wall',
            vertices: [vertices[i], vertices[endVertexIndex]],
            position: getCenterOfTiltedLine(vertices[i], vertices[endVertexIndex]),
            rotation: getTwoDMeshRotation(vertices[i], vertices[endVertexIndex]),
            width: getTwoDMeshWidth(vertices[i], vertices[endVertexIndex]),
            children: []
        }
        twoDMeshes.push(wallMesh);
    }
    return twoDMeshes;
}

export const getCenterOfTiltedLine = (point1: TwoDVertex, point2: TwoDVertex) => {
    // Calculate the midpoint of the line
    const centerX = (point1.x + point2.x) / 2;
    const centerY = (point1.y + point2.y) / 2;

    // Calculate the angle of rotation (tilt)
    const slope = Math.abs(point2.y - point1.y) / Math.abs(point2.x - point1.x);
    const tiltAngle = Math.atan(slope);
    const angleInRadians = (tiltAngle * Math.PI) / 180;

    const distance = Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    const midpointX = point1.x + (distance / 2) * Math.cos(angleInRadians);
    const midpointY = point1.y + (distance / 2) * Math.sin(angleInRadians);

    return { x: centerX, y: centerY };
}

export function findEndpoints(midpointX: number, midpointY: number, angleInDegrees: number, length: number) {
    const angleInRadians = (angleInDegrees * Math.PI) / 180;
    const endpoint1X = midpointX - (length / 2) * Math.cos(angleInRadians);
    const endpoint1Y = midpointY - (length / 2) * Math.sin(angleInRadians);
    const endpoint2X = midpointX + (length / 2) * Math.cos(angleInRadians);
    const endpoint2Y = midpointY + (length / 2) * Math.sin(angleInRadians);
    return {
        endpoint1: { x: endpoint1X, y: endpoint1Y },
        endpoint2: { x: endpoint2X, y: endpoint2Y },
    };
}

export function intersectOfLines(
    x1: number, y1: number,
    x2: number, y2: number,
    x3: number, y3: number,
    x4: number, y4: number) {

    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return false
    }

    const denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

    // Lines are parallel
    if (denominator === 0) {
        return false
    }

    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
    let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false
    }

    // Return a object with the x and y coordinates of the intersection
    let x = x1 + ua * (x2 - x1)
    let y = y1 + ua * (y2 - y1)

    return { x, y }
}

export function getPointAtDistanceAndAngle(sourcePoint: TwoDVertex, distance: number, angleInDegrees: number) {
    // Convert the angle to radians
    const angleInRadians = (angleInDegrees * Math.PI) / 180;

    // Calculate the new coordinates
    const x2 = sourcePoint.x + distance * Math.cos(angleInRadians);
    const y2 = sourcePoint.y + distance * Math.sin(angleInRadians);

    return { x: x2, y: y2 };
}

export function getParallelLine(x1: number, y1: number, x2: number, y2: number, offset: number) {
    // Calculate slope of the original line
    let m = (y2 - y1) / (x2 - x1);

    // Calculate the perpendicular slope
    let m_perpendicular = -1 / m;

    // Calculate angle theta between original line and x-axis
    let theta = Math.atan(m);

    // Calculate new points for the parallel line
    let dx = offset * Math.sin(theta); // Offset in the x direction
    let dy = offset * Math.cos(theta); // Offset in the y direction

    let new_x1 = x1 + dx;
    let new_y1 = y1 - dy; // Negative because we want the line above
    let new_x2 = x2 + dx;
    let new_y2 = y2 - dy;

    return { x1: new_x1, y1: new_y1, x2: new_x2, y2: new_y2 };
}