import { createTheme } from '@mui/material/styles';
import { defaultTheme } from './base';

let theme = defaultTheme();

export const homestylesTheme = createTheme(theme, {
  palette: {
    primary: {
      main: '#BE4F2E'
    }
  }
});