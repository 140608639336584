import axios from "axios";
import { Auth } from 'aws-amplify';
import { decodeToken, getAWSCredentials, getGoogleCredentialsFromLS, promptLoginToUser, setGoogleToken } from "../load_and_get_google_access_token";
import getAwsConfig from '@/utils/aws-config';
import aws4Interceptor from "@/utils/aws4Interceptor";
import { getFBKAWSCredentials, getFBKCredentialsFromLS, signInWithFacebook } from "@/utils/load_and_get_facebook_access_token";
import { setDirectCognitoCredentialsIntoLS } from "@/components/login";
import { getAppleCredentialsFromLS, signInWithApple } from "@/utils/load_and_get_apple_access_token";
import { signOutUser } from "@/utils/useUserInfo";

const awsConfigObj = getAwsConfig({domain:"", protocol:""})

if(typeof window != 'undefined'){
    window.nestingale.retryAPIURLsArray = [];

    window.nestingale.getCredentialsForIdentity =  function() {
        return new Promise(async (resolve, reject) => {
          try {
            //Retrieve the current authenticated user from Cognito
            const user = await Auth.currentAuthenticatedUser();
            let currentCredentials = await Auth.currentCredentials();
            if(currentCredentials.authenticated){
                //Amplify typically exchanges Google/Facebook sign-in tokens for Cognito session tokens, resulting in "iss": "cognito-idp". This is expected and usually doesn't require re-prompting.
                return resolve(currentCredentials);
            }else{
              throw new Error("ask for prompt");
            }
          } catch (error) {
            const googleCredentails = getGoogleCredentialsFromLS();
            const fBkCredentials = getFBKCredentialsFromLS();
            const appleCredentials = getAppleCredentialsFromLS();
            if(googleCredentails){
              function promptLogin(){
                  // if google token is present means he must be prompted with google prompt
                  if (window.google && window.google?.accounts) {
                    clearInterval(window.nestingale.promptLoginId);
                    return promptLoginToUser();
                  } else {
                    console.log('reset the GOOGLE ls and redirect to login');
                    signOutUser(/*reset the ls and redirect to login*/true);
                  }
              } 
              if(!window.nestingale.promptLoginId){
                //there is a timeDelay in google SDK loading.         
                window.nestingale.promptLoginId = setInterval(promptLogin,50)
              }
            }else if(fBkCredentials){
              function promptLogin(){
                  // if google token is present means he must be prompted with google prompt
                  if (window.FB) {
                    clearInterval(window.nestingale.promptLoginId);
                    return signInWithFacebook();
                  } else {
                    console.log('reset the FB ls and redirect to login');
                    signOutUser(/*reset the ls and redirect to login*/true);
                  }
              } 
              if(!window.nestingale.promptLoginId){
                //there is a timeDelay in facebook SDK loading.         
                window.nestingale.promptLoginId = setInterval(promptLogin,50)
              }
            } else  if(appleCredentials){
              function promptLogin(){
                  // if apple token is present means he must be prompted with apple prompt
                  if (window.apple) {
                    clearInterval(window.nestingale.promptLoginId);
                    return signInWithApple();
                  } else {
                    console.log('reset the APPLE ls and redirect to login');
                    signOutUser(/*reset the ls and redirect to login*/true);
                  }
              } 
              if(!window.nestingale.promptLoginId){
                //there is a timeDelay in facebook SDK loading.         
                window.nestingale.promptLoginId = setInterval(promptLogin,50)
              }
            }else{
                //else send guest accessToken
                try {
                  const currentCredentials =  await Auth.currentCredentials();
                  // const accessToken = user.signInUserSession.idToken.jwtToken
                  console.log('Guest access token:', currentCredentials);
                  return resolve(currentCredentials)
                }catch(e){
                  console.error('Error getting credentials: ', error);
                  console.error('Error guest getting credentials: ', e);
                }
                //after some time without login getting no user from AWS
                reject(error)
            }
    
          }
        })
    }
}

  
// Refresh the temporary credentials every 5 minutes
//const intervalId = setInterval(window.nestingale.getCredentialsForIdentity, 5 * 60 * 1000);

const axiosObj = axios.create({
  baseURL: "https://d221a61rb87vel.cloudfront.net/v1",
  //headers: {
  //  'Customer-Id': typeof getCookie != 'undefined' ? getCookie('userId') : "",
  //}
});
 

axiosObj.interceptors.request.use(async function (config) {  
    try{
        if(typeof window !== 'undefined'){
            if(!window.nestingale.credentials){
              const credentails = await window.nestingale.getCredentialsForIdentity()
              console.log("credentials:: ",window.nestingale.credentials);
              console.log("return credentails::",credentails);
              window.nestingale.credentials = credentails;
              window.nestingale.retryAPIURLsArray = [];
            }
            
            //Merge the overiden headers and form the final headers. 
            config.headers = Object.assign({
              'content-type': "application/json",
              //"Authorization": `Bearer ${window.nestingale.credentials.sessionToken}`
            },config.headers) 

            if ( !config.url.startsWith('https') ) {
              config.url = config.baseURL + config.url;
            }
            
            return aws4Interceptor(
              {
                region:  awsConfigObj.Auth.region,
                service: "execute-api",
              },
              window.nestingale.credentials 
            )(config)
        } else {
          try {
            const currentCredentials = await Auth.currentCredentials();
            // const accessToken = user.signInUserSession.idToken.jwtToken
            //console.log('Guest access token:', currentCredentials);
      
            //Merge the overiden headers and form the final headers. 
            config.headers = Object.assign({
              'content-type': "application/json",
              //"Authorization": `Bearer ${window.nestingale.credentials.sessionToken}`
            }, config.headers)
            
            if ( !config.url.startsWith('https') ) {
              config.url = config.baseURL + config.url;
            }

            return aws4Interceptor(
              {
                region: awsConfigObj.Auth.region,
                service: "execute-api",
              },
              currentCredentials
            )(config)
          } catch (e) {
            console.error('Error guest getting credentials: ', e);
          }
        }
        //return config;

    }catch(e){
      //debugger
      console.log(e)
    }
    return config;
  }, function (error) {
        return Promise.reject(error);
});


// Add a response interceptor
axiosObj.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, async function (error) {
  if((typeof window !== 'undefined') && error.request.status == 401) {
    if(window.nestingale.retryAPIURLsArray.includes(error.config.url)) {
      await window.nestingale.getCredentialsForIdentity();
      return;
    }
    window.nestingale.retryAPIURLsArray.push(error.config.url);
    window.nestingale.credentials = "";
    return this.request(error.config);
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  throw error;
}.bind(axiosObj));


export default axiosObj;

  //window.nestingale.saveOrUpdateAccessToken = function(user){
   
    /*return new Promise(async (resolve, reject) => {
      try {        
        console.log(user.signInUserSession)
        if(isTokenExpired(user.signInUserSession.accessToken.jwtToken)){
            user.refreshSession(user.getSignInUserSession().getRefreshToken(), (error, newSession) => {
              if (error) {
                console.log('Error refreshing access token:', error);
              } else {
                // The new access token can be accessed in the newSession object
                window.nestingale.accessToken = newSession.getAccessToken().getJwtToken();
                console.log('New access token:', window.nestingale.accessToken);
                resolve(window.nestingale.accessToken)
              }
            });
        }else {
           window.nestingale.accessToken = user.signInUserSession.idToken.jwtToken;
           resolve(window.nestingale.accessToken)
        };
      }catch(e){
        console.error("error",e);
      }
    })*/
  //}

   // Create a new CognitoIdentity object
        // const cognitoIdentity = new AWS.CognitoIdentity({
        //   region: "us-east-1" // replace with your desired region
        // });

        // Retrieve the identity ID for the authenticated user        
        // const identityId = await cognitoIdentity.getId({
        //   IdentityPoolId: awsConfig.Auth.identityPoolId
        // }).promise();
        //const providerName = user.signInUserSession.idToken.payload.identities[0].providerName
        //  let provider = `cognito-idp.${awsConfig.Auth.region}.amazonaws.com/${awsConfig.Auth.userPoolId}`
        // if(providerName=='Google'){
        //     provider = 'accounts.google.com';
        // }else if(providerName == 'facebook'){
        //     provider = 'graph.facebook.com'
        // }
        // Call the getCredentialsForIdentity method to retrieve temporary AWS credentials
        // const params = {
        //   IdentityId: identityId.IdentityId,
        //   Logins: {
        //     [provider]: user.signInUserSession.idToken.jwtToken
        //   }
        // };
        // params.IdentityId = identityId.IdentityId.toString(); // Make sure IdentityId is a string

        //const data = await cognitoIdentity.getCredentialsForIdentity(params).promise();