import { Auth } from 'aws-amplify';
import jwt from 'jwt-decode';
import { deleteFBKCredentialsFromLS } from './utils/load_and_get_facebook_access_token';
import { getCurrentBrand, getUserInfo, removeCustIdFromLS, signOutUser } from './utils/useUserInfo';
import { redirectToNextPage } from './components/login';
import { deleteAppleCredentialsFromLS } from './utils/load_and_get_apple_access_token';

export const renderGoogleButton = () => {
    try{
        //@ts-ignore
        window.google.accounts.id.renderButton(
            document.getElementById("googleSignInButton"),
            { theme: "outline", size: "large" }
        );
    }catch(e){}
}

const GOOGLE_CLIENT_ID = "786814182697-4hlbplukcgrp2mjduu45nkdgsvr60rrs.apps.googleusercontent.com";

export const deleteGoogleCredentials = () => {
  localStorage.removeItem("gtn");
}

export const setGoogleCredentialsIntoLS = (tokenValue: any) => {
    localStorage.setItem("gtn",JSON.stringify(tokenValue));
}

export const getGoogleCredentialsFromLS = () => {
  try{
    const value  = localStorage.getItem("gtn");
    if(value){
      return JSON.parse(value);
    }
    return ""
  }catch(e){
    return ""
  }
}

// Initialize Google client and render Google button
export const initGsi = (router:any, loginCallback:Function) => {
    //@ts-ignore
    if (window.google && window.google?.accounts) {
      //@ts-ignore
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: (response: any) => {
          //@ts-ignore
          //update STATE HERE using addeEVENT LISTENER
          window.nestingale.googleAccessToken = response.credential;
          getAWSCredentials(response.credential, router, loginCallback);
          //
        },
      });
      renderGoogleButton();
    }
}

export var dynamicLoadScript = (callback:any,url: string) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  script.onload = () => {
    callback();
  };
  document.head.appendChild(script);
}

// Load the Google client
export const createScript = (setLoaded:any) => {
  dynamicLoadScript(()=>{
    dynamicLoadScript(()=>{
      gapi.load('client',()=>{
        gapi.client.load('https://www.googleapis.com/discovery/v1/apis/oauth2/v1/rest')
        .then(function(){
            //TODO:: need to check if its depcreated????
            //@ts-ignore
                gapi.client.oauth2.userinfo.get().execute(function (resp) {
                    // Shows user email
                    gapi.auth2.init({
                      client_id: GOOGLE_CLIENT_ID,
                      scope: 'profile email openid',
                    });
                    const urlParams = new URLSearchParams(window.location.search);
                    const token = urlParams.get('token');
                    if(token){
                      //@ts-ignore
                      window.nestingale.googleAccessToken = token;
                      //@ts-ignore
                      gapi.client.setToken({ access_token: token  });
                      getAWSCredentials(token,null)
                    }
                    
                    setLoaded(true);
                })
        },
        function(err){
            var errorString = "";
            try{
                errorString = JSON.stringify(err);
            }catch(e){
                errorString = err;
            }
            console.log({description: "GoogleApi:loadClient::userinfo:get", errorCode: "INLINE_INGESTION" , err_desc: errorString})
        })
      })
      
     
    },'https://accounts.google.com/gsi/client')
  },'https://apis.google.com/js/api.js')
}

export const promptLoginToUser  = () => {
  //@ts-ignore
  window.google.accounts.id.prompt((notification) => {
    if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
      //continue with another identity provider or guest flow.
      signOutUser()
      //@ts-ignore
      window.location.reload();
    }
  });
} 

export const decodeToken = (token:string) => {
  return jwt(token)
}

export const isTokenExpired = (token:string) => {
  const decodedToken = decodeToken(token) as any;
  return isTokenExpiredByTime(decodedToken.exp);
};

export const isTokenExpiredByTime = (time:number) => {
  const expirationTime = time * 1000;
  const currentTime = new Date().getTime();
  return currentTime > expirationTime;
};


 // Exchange Google token for temporary AWS credentials
 export const getAWSCredentials = async (credential: string, router: any, loginCallback?: Function) => {
  const token = jwt(credential) as any;
  const user = {
    email: token.email,
    name: token.name,
    firstName: token.given_name,
    lastName: token.family_name,
    picture: token.picture
  };
  let credentials;
  try{

    if (isTokenExpiredByTime(token.exp)) {
      throw new Error('Google token is expired');
    }

    credentials = await Auth.federatedSignIn(
      'google',
      { token: credential, expires_at: token.exp },
      user
    )
  }catch(e){
    // Handle the error here
    console.log("Error for federatedSignIn Google",e)
    alert("please retry the Google authentication process" + e);
    promptLoginToUser();
    return;
  }
  removeCustIdFromLS();
  deleteFBKCredentialsFromLS();
  deleteAppleCredentialsFromLS();
  setGoogleCredentialsIntoLS({...user,...credentials});
  let userInfo;
  try{
    userInfo = await getUserInfo("",true);
  }catch(e){
    console.log("Failed to save Google email Address",e)
  }
  try{
    if (loginCallback) {
      loginCallback({type: "google", userInfo: userInfo, status: "LOGIN_SUCCESS"});
    } else {
      //@ts-ignore
      window.nestingale && (window.nestingale.triggeredRedirection = true);
      redirectToNextPage();
    }

  }catch(e){}

  return credentials;
} 