import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import productsSlice from "./productSlice";
import homeSlice from "./homeSlice";
import commonSlice from "./commonSlice";
import shopifyReducer from "./shopifyReducer";
import questionniareSlice from "./questionniareSlice";
import { createWrapper } from "next-redux-wrapper";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import builderSlice from "./builderSlice";

const rootReducer = combineReducers({
  products: productsSlice,
  home: homeSlice,
  common: commonSlice,
  builder: builderSlice,
  shopifyReducer : shopifyReducer,
  welcomePg: questionniareSlice
});

export const makeStore = () =>
  configureStore({
    reducer: rootReducer,
    devTools: true,
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
interface RootState {
  property: string;
}
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const wrapper = createWrapper<AppStore>(makeStore, { debug: true });
