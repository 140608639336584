import styles from "./nesterror.module.scss";

export interface ErrorProps {
    url?: string;
    method?: string;
    payload?: any;
    statusCode?: number;
    response?: any;
    message?: string;
    stackTrace?: string;
}

export const prepareErrorObj = (error: any) => {
    return {
        url: error.config.url,
        method: error.config.method,
        payload: error.config.data,
        statusCode: error.response && error.response.status ? error.response.status : 500,
        response: error.response ? error.response.data : 'No response',
    }
}


export function prepareErrorMessage(pageProps: any) {
    const { error, statusCode } = pageProps;
    let errorObj = JSON.parse(error) || {};
    let errorMsg: any = { "message": errorObj.message, "stack": errorObj.stack };
    try {
        let errorMsgStr = JSON.stringify(error, null, 2);
        if (errorMsgStr === "{}") {
            errorMsg = JSON.stringify({ "message": error.toString() });
        } else {
            errorMsg = errorMsgStr;
        }
    } catch (e) {
        errorMsg = JSON.stringify(error, null, 2);
    };
    console.error(errorMsg);
    return errorMsg;
}

export function prepareErrorProps(error: any) {
    let errorMsg: ErrorProps = {
        message: error.message,
        stackTrace: error.stack
    }
    return {
        props: {
            error: errorMsg,
            title: "Error | Nestingale",
            dataLayer: {
                pageType: "Error Page",
                pageName: "Error"
            }
        },
        revalidate: 1
    }
}


const NestError: React.FC = (props: any) => {
    const env = process.env.NEXT_PUBLIC_APP_ENV === 'qa' ? 'qa' : 'prod';
    const { error } = props;
    return (
        <div className={styles["error-container"]}>
            <h2>Something went wrong, error details: </h2>
            {error.url && <p><strong>URL:</strong> {error.url}</p>}
            {error.method && <p><strong>Method:</strong> {error.method}</p>}
            {(error.statusCode) && <p><strong>Status Code:</strong> {error.statusCode}</p>}
            {error.payload && <p><strong>Request Payload:</strong> {JSON.stringify(error.payload)}</p>}
            {error.response && <p><strong>Response:</strong> {JSON.stringify(error.response)}</p>}
            {error.message && <p><strong>Message:</strong> {error.message}</p>}
            {env != "prod" && error.stackTrace && <p><strong>Stack Trace:</strong> {error.stackTrace}</p>}
        </div>
    );
};

export default NestError;