import loadConfig from "./loadConfig";
import loadFeatureCatalog from "./loadFeatureCatalog";

export default async function loadBuilderConfig(roleName: string) {

    const [featureCatalog, config] = await Promise.all([
        loadFeatureCatalog(roleName),
        loadConfig(`builder_config`)
    ]);
    const builderConfig = {
        featureCatalog: Object.assign({}, featureCatalog),
        config: Object.assign({}, config),
        env: ''
    }
    return builderConfig;
}