'use client';

import React, { useRef } from "react";
import ClientComponent from "./client_component";
import { Button, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { Project, actions as builderActions } from "@/store/builderSlice";
import BuilderSelct from "@/store/builderStoreSelector";
import { BUILDER_IN_PROGRESS } from "@/utils/builder/builderConstants";
import { saveProject as saveProjectThunk } from "@/store/builder/thunks/projectSaveThunk";
import { BUILDER_SUCCESS } from '@/utils/builder/builderConstants';
import cssStyles from './builder.module.scss';

const BuyNowComponent = () => {
    const dispatch = useDispatch();
    const project: Project = BuilderSelct.project();
    const saveStatus: string = BuilderSelct.ui().saveStatus;
    const urlParams: { [key: string]: string } = BuilderSelct.ui().urlParams;
    const isShowCaseFlow = urlParams.showcase === 'true';
    const buyNowRef = useRef(false);

    const saveAndNavigateToProjectPage = () => {
        buyNowRef.current = true;
        dispatch(builderActions.updateProjectStatus({ status: 'ready_to_buy' }));
        dispatch(builderActions.updateSaveStatus({ saveStatus: BUILDER_IN_PROGRESS }));
        dispatch(saveProjectThunk({}) as any);
    }

    if (buyNowRef.current && saveStatus === BUILDER_SUCCESS) {
        buyNowRef.current = false;
        const origin =
            typeof window !== 'undefined' && window.location.origin
                ? window.location.origin
                : '';
        let projectUrl = `${origin}/project?projectId=${project.projectId}`;
        if (isShowCaseFlow) {
            projectUrl += '&showcase=true&auth=false'
        }
        window.location.href = projectUrl;
    }

    return <Grid item>
        <Button
            variant="contained"
            onClick={saveAndNavigateToProjectPage}
            size="small"
            disabled={project.products.length < 1}
            className={cssStyles["next-button"]}
        >
            Buy Now
        </Button>
    </Grid >
}

export default ClientComponent(BuyNowComponent);