
import {createSlice, createAsyncThunk, current} from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { HYDRATE } from "next-redux-wrapper";
import axiosObj from './axios';
import { LOADING, SUCCESS, FAILED, EMPTY} from './constants';
import { prepareErrorObj } from '@/components/nesterror';

export interface Size {
  width: number;
  height: number;
}

export enum SelectedType {
  PRODUCT_TYPE,
  PRODUCT_SUB_TYPE,
  PRODUCT_ID
}

export interface ProductsParams {
  type: string
}

export interface Filter {
  id: string,
  name: string,
  displayName: string,
  hexaCode: string,
  image: string,
  callback?: Function
}

export interface FilterType {
  name: string,
  displayName: string
}

export interface ProductMenuItem {
  name: string,
  displayName: string,
  selected?: boolean,
  items: Filter[],
  link?: string
  callback?: Function
}

export interface DynamicFilter {
  name: string,
  filterItems?: DynamicFilterItem[],
  selectedFilterValues?: string[],
  isActive?: boolean
  hideFromSelection?: boolean
}

export interface DynamicFilterItem {
  count: number,
  filterValue: string,
  isActive?: boolean
  displayname?: string,
  hexaCode?: string

}
//we already have ProductData type, this is for SSR only to reduce the size of the data, may need to refactor later
export interface Product {
  id?: string,
  name?: string,
  productType?: string,
  productTypeDisplayName?: string,
  productSubType?: string,
  productSubTypeDisplayName?: string,
  image?: string,
  model3dUrl?: string,
  price?: number,
  comparePrice?: number,
  pdpUrl?: string,
  viewMoreUrl?: string,
  vendorName?: string,
  expiresOn?: string,
  viewInYourRoomLink?: string,
  otherSizes?: any,
  otherColors?: any,
  priceRange?: any,
}

export interface ProductDesignImage {
  productType?: string,
  productTypeDisplayName?: string,
  name: string,
  id: string,
  pdpUrl: string,
  productDesignImage: string,
}

export interface SeoItem {
  name: string,
  type: string,
  seoText: string,
}

    const getInitialState = (data:any)=> {
      return {              
            error: "",
            loading: LOADING,
            productsLoading: data.productsLoading || LOADING,
            mainMenuFiltersLoading: data.mainMenuFiltersLoading || LOADING,
            subTypeFiltersLoading: data.subTypeFiltersLoading || LOADING,

            currentRequestId: data.currentRequestId || null,
            
            brand: data.brand|| null,
            productId: data.productId|| null,
            productType: data.productType|| null,
            mainMenuFilterType: data.mainMenuFilterType || null,
            mainMenuFilterName: data.mainMenuFilterName || null ,
            subTypeFilterName: data.subTypeFilterName || null,
            subTypeFilterDisplayName: data.subTypeFilterDisplayName|| null,

            vendorsData: data.vendorsData || {},
            productDetailData: data.productDetailData || {},
            productSubTypesData: data.productSubTypesData || {},
            productStylesData: data.productStylesData || {},
            productsData: data.productsData || {},  

            mainMenuFilters: data.mainMenuFilters || [],
            subTypeFilters: data.subTypeFilters || [],
            productDesignImages: data.productDesignImages || [],
            products: data.products || {}, 
            dynamicFilters: data.dynamicFilters || [],
            selectedFacetsList: data.selectedFacetsList || {},
            activeDynFilterName: data.activeDynFilterName|| null,
            dynamicFilterSelection: data.dynamicFilterSelection || null,

            productSubTypes: data.productSubTypes || {},
            productRooms: data.productRooms || {},
            productStyles: data.productStyles || {},
            productColors: data.productColors || {},
            productMaterial: data.productMaterial || {}
    }
  }

const brand = undefined; //getBaseName().name;

export const getproductDetailData = (state: any) => state.productCatPge.productDetailData;
export const getproductSubTypesData = (state: any) => state.productCatPge.productSubTypesData;
export const getproductStylesData = (state: any) => state.productCatPge.productStylesData;
export const getproductsData = (state: any) => state.productCatPge.productsData;
export const getMainMenuFilters = (state: any) => state.productCatPge.mainMenuFilters;
export const getSubTypeFilters = (state: any) => state.productCatPge.subTypeFilters;
export const getProductDesignImages = (state: any) => state.productCatPge.productDesignImages;
export const getProducts = (state: any) => state.productCatPge.products;
//export const brand = (state: any) => state.reducer.brand;

export const getMainMenuFiltersData = async (config: {productTypeName: string, brand?: string, env?: string}) => {
      let url = `/api/filters?productType=${config.productTypeName}`; 
      if(config.brand && config.brand !== 'nestingale'){
        url += "&vendor="+config.brand 
      }
      if (config.env && config.env !== 'prod') {
        url += (url.includes("?") ? "&env=" : "?env=") + config.env
      }
      return await axiosObj.get(url)
        .then((response) => {
          //console.log("---- fetchMainMenuFilters  Response : " + JSON.stringify(response.data));
            return response.data;
        },(err)=>{
          const errorObj = Object.assign({description: "NEST_SPA:Slices:fetchMainMenuFilters:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE"}, prepareErrorObj(err))
          console.log(errorObj);
          throw errorObj;
      });
  }

export const fetchMainMenuFilters = createAsyncThunk(
  "products/fetchMainMenuFilterTypesRes",
  async (config: {productTypeName: string, brand?: string}, { rejectWithValue }) => {
    try {
      let url = `/api/filters?productType=${config.productTypeName}`; 
      if(config.brand && config.brand !== 'nestingale'){
        url += "&vendor="+config.brand 
      }
      const filtersData = await axiosObj.get(url)
          .then((response) => {
            //console.log("---- fetchMainMenuFilters  Response : " + JSON.stringify(response.data));
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:fetchMainMenuFilters:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE"},err)
            console.log(errorObj);
            throw errorObj;
          });
      return filtersData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchMainMenuFilters:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);


export const fetchFilters = createAsyncThunk(
  "products/fetchFiltersRes",
  async (config: {productTypeName: string, mainMenuFilterType: string, brand?: string}, { rejectWithValue }) => {
    try {
      let url = `/api/filterValues?productType=${config.productTypeName}&dataType=${config.mainMenuFilterType}`; 
      if(config.brand && config.brand !== 'nestingale'){
        url += "&vendor="+config.brand 
      }
      const filtersData = await axiosObj.get(url)
          .then((response) => {
            //console.log("---- fetchFilters Response : " + JSON.stringify(response.data));
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:fetchFilters:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE"},err)
            console.log(errorObj);
            throw errorObj;
          });

      return filtersData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchFilters:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);
export const getSubTypeFiltersData = async (config: {productTypeName: string, mainMenuFilterType?: string,  mainMenuFilterName? : string, brand?: string, env?: string}) => {
      let url = `/api/filterValues?productType=${config.productTypeName}&dataType=productSubType`;
      if(config.mainMenuFilterType && config.mainMenuFilterName) {
        url += `&filters=${config.mainMenuFilterType}:${config.mainMenuFilterName}`
      } 
      if(config.brand && config.brand !== 'nestingale'){
        url += "&vendor="+config.brand 
      }
      if (config.env && config.env !== 'prod') {
        url += (url.includes("?") ? "&env=" : "?env=") + config.env
      }
      return await axiosObj.get(url).then((response) => {
        //console.log("---- fetchSubTypeFilters URL : "+ url +" Response : " + JSON.stringify(response.data));
          return response.data;
      },(err)=>{
        const errorObj = Object.assign({description: "NEST_SPA:Slices:fetchFilterSubTypesRes:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE"}, prepareErrorObj(err))
        console.log(errorObj);
        throw errorObj;
      });
  }

export const fetchSubTypeFilters = createAsyncThunk(
  "products/fetchFilterSubTypesRes",
  async (config: {productTypeName: string, mainMenuFilterType?: string,  mainMenuFilterName? : string, brand?: string}, { rejectWithValue }) => {
    try {
      let url = `/api/filterValues?productType=${config.productTypeName}&dataType=productSubType`;
      if(config.mainMenuFilterType && config.mainMenuFilterName) {
        url += `&filters=${config.mainMenuFilterType}:${config.mainMenuFilterName}`
      } 
      if(config.brand && config.brand !== 'nestingale'){
        url += "&vendor="+config.brand 
      }
      const filtersData = await axiosObj.get(url)
          .then((response) => {
            //console.log("---- fetchSubTypeFilters URL : "+ url +" Response : " + JSON.stringify(response.data));
             return response.data;
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:fetchFilterSubTypesRes:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE"},err)
            console.log(errorObj);
            throw errorObj;
          });

      return filtersData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchFilterSubTypesRes:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);

export const getProductsData = async (config: { productTypeName?: string, sortBy?: string, filterBy?: string, brand?: string, env?: string }) => {
  let url = `/api/products?`
  if (config.productTypeName) {
    url += `&productType=${config.productTypeName}`;
  }
  if (config.filterBy) { // filters=room:Bedrooms;productSubTypeName:doubledoorchest
    url += `&filters=${config.filterBy}`;
  }
  if (config.sortBy) {
    url += `&sortBy=${config.sortBy}`;
  }
  if (config.brand && config.brand !== 'nestingale') {
    url += "&vendor=" + config.brand
  }
  if (config.env && config.env !== 'prod') {
    url += (url.includes("?") ? "&env=" : "?env=") + config.env
  }
  return await axiosObj.get(url)
    .then((response) => {
      //console.log("---- fetchProducts : URL : "+ url +" responnse : " + JSON.stringify(response.data));
      return response.data
    }, (err) => {
      const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchProductsData:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE" }, prepareErrorObj(err))
      throw errorObj;
    });
}

export const getProductsByIds = async (config: { productIds: string[], brand?: string, env?: string }) => {
  let url = `/api/products?productIds=${config.productIds.join(",")}`;
  if (config.brand && config.brand !== 'nestingale') {
    url += "&vendor=" + config.brand
  }
  if (config.env && config.env !== 'prod') {
    url += (url.includes("?") ? "&env=" : "?env=") + config.env
  }
  return await axiosObj.get(url)
    .then((response) => {
      return response.data
    }, (err) => {
      const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchProductsData:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE" }, prepareErrorObj(err))
      throw errorObj;
    });
}





  export const getProductData = async (config: { productId?: string, brand?: string, env?: string}) => {
  let url = `/api/productDetails?productId=${config.productId}&includeRecommendations=true`;
  if (config.brand && config.brand !== 'nestingale') {
    url += "&vendor=" + config.brand
  }
  if (config.env && config.env !== 'prod') {
    url += (url.includes("?") ? "&env=" : "?env=") + config.env
  }
  return await axiosObj.get(url)
    .then((response) => {
      return response.data
    }, (err) => {
      const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchProductsData:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE" }, prepareErrorObj(err))
      throw errorObj;
    });
}

export const fetchProducts = createAsyncThunk(
  "products/productsRes",
  async (config: {productTypeName?: string, filterBy?: string , brand?: string}, { rejectWithValue }) => {
    try {
      let url = `/api/products?` 
      if(config.productTypeName) {
        url+= `&productType=${config.productTypeName}`;
       } 
       if(config.filterBy) { // filters=room:Bedrooms;productSubTypeName:doubledoorchest
        url+= `&filters=${config.filterBy}`;
       }
       if(config.brand && config.brand !== 'nestingale'){
        url += "&vendor="+config.brand 
       }
      const productsData: any = await axiosObj.get(url)
          .then((response) => {
            //console.log("---- fetchProducts : URL : "+ url +" responnse : " + JSON.stringify(response.data));
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:fetchProductsData:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE"},err)
            throw errorObj;
          });

          //console.log("productsData=",productsData)
      return productsData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchProductsData:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);

// FOR BUILDER PAGE
export const fetchProductSubTypes = createAsyncThunk(
  "products/productSubTypesRes",
  async (config: {productTypeName: string}, { rejectWithValue }) => {
    try {
      let url = `/api/productSubTypes?productTypeName=${config.productTypeName}`; 
      if(brand && brand !== 'nestingale'){
        url += "&vendor="+brand 
      }
      const productSubTypesData = await axiosObj.get(url)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:fetchProductSubTypes:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE"},err)
            console.log(errorObj);
            throw errorObj;
          });

          //console.log("productSubTypesData=",productSubTypesData)
      return productSubTypesData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchProductSubTypes:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);
// FOR BUILDER PAGE
export const fetchProductStyles = createAsyncThunk(
  "products/productStylesRes",
  async (config: {brand: string, productTypeName?: string, productSubTypeName?: string}, { rejectWithValue }) => {
    try {
      
      let url = "/api/styles?";
      if(config.productTypeName) {
        url+= `productTypeName=${config.productTypeName}`;
       } else if(config.productSubTypeName) {
        url+= `productSubTypeName=${config.productSubTypeName}`;
       } else {
          rejectWithValue("Invalid input")
       }
       if(brand && brand !== 'nestingale'){
          url += "&vendor="+brand 
       }
      
      const productStylesData = await axiosObj.get(url)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:fetchProductStyles:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE"},err)
            console.log(errorObj);
            throw errorObj;
          });

          //console.log("productStylesData=",productStylesData)
      return productStylesData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchProductStyles:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);

// FOR BUILDER PAGE
export const fetchProductsData = createAsyncThunk(
  "products/productsDataRes",
  async (config: {productTypeName?: string, productSubTypeName?: string, styleName: string}, { rejectWithValue }) => {
    try {
      let url = `/api/products?styleName=${config.styleName}`; 
      if(config.productTypeName) {
        url+= `&productTypeName=${config.productTypeName}`;
       } else if(config.productSubTypeName) {
        url+= `&productSubTypeName=${config.productSubTypeName}`;
       } else {
        rejectWithValue("Invalid input")
       }
       if(brand && brand !== 'nestingale'){
        url += "&vendor="+brand 
       }
      const productsData = await axiosObj.get(url)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:fetchProductsData:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE"},err)
            console.log(errorObj);
            throw errorObj;
          });

          //console.log("productsData=",productsData)
      return productsData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchProductsData:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);

//For PTD page
export const fetchProductDetails = createAsyncThunk(
  "products/productDetailsRes",
  async (config: {productId: string, brand?: string}, { rejectWithValue }) => {
    try {
      let url = `/api/productDetails?productId=${config.productId}`; 
      if(config.brand && config.brand !== 'nestingale'){
        url += "&vendor="+config.brand 
      }
      const productDetailsData = await axiosObj.get(url)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:fetchProductDetails:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE"},err)
            console.log(errorObj);
            throw errorObj;
          });

          //console.log("productDetailsData=",productDetailsData)
      return productDetailsData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchProductDetails:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);


//---------- SLICES SECTION ------------

const productsSlice = createSlice({
    name: "products",
    initialState: getInitialState({}),
    // create separate file with state and reducers and import here
    reducers: {

      setVendorBrandName: (state, action) => {
        //console.log(" -- productsSlice -- brand "+ JSON.stringify(action.payload))
        state.brand = action.payload;
      },

      setProductType: (state, action) => {
        //console.log(" -- productsSlice -- productType "+ JSON.stringify(action.payload))
        state.productType = action.payload;
      },
      mainMenuFilterTypeSelected: (state, action) => {
        //console.log(" --productsSlice-- mainMenuFilterTypeSelected "+ JSON.stringify(action.payload))
        if( state.mainMenuFilterType !== action.payload) {
          state.mainMenuFilterType = action.payload;
        }
      },
      mainMenuFilterNameSelected: (state, action) => {
        //console.log(" --productsSlice-- mainMenuFilterNameSelected "+ JSON.stringify(action.payload))
        if(state.mainMenuFilterName !== action.payload) {
          state.mainMenuFilterName = action.payload;
          state.subTypeFilterName = null;
          state.dynamicFilters = null;
        }
      },
      subTypeFilterNameSelected: (state, action) => {
        //console.log(" --productsSlice-- subTypeFilterNameSelected "+ JSON.stringify(action.payload))
        if(state.subTypeFilterName !== action.payload.subTypeFilterName) {
          state.subTypeFilterName = action.payload.subTypeFilterName || null;
          state.subTypeFilterDisplayName = action.payload.subTypeFilterDisplayName || null;
          if(action.payload.onLoad == false) {
            state.dynamicFilters = null;
            state.dynamicFilterSelection = null;
          }
        }
      },
      setProductId: (state, action) => {
        //console.log(" --productsSlice-- subProductId "+ JSON.stringify(action.payload))
        state.productId = action.payload
      },
      updateMainMenu: (state, action) => {
        state.mainMenuFilters[action.payload.type].items = action.payload.filters;
      },
      updateDynamicMenuSelection : (state, action) => {
        //console.log(" --productsSlice-- updateDynamicMenuSelection : "+ JSON.stringify(action.payload))

        let updatedDynamicFilter = action.payload;

        // ON DYNAMIC MENU CLICK, SETTING UP SPECIFIC FILTER AS ACTIVE
        if (updatedDynamicFilter.isActive) {
          state.dynamicFilters?.map((dynamicFilter: DynamicFilter) => {
            if( dynamicFilter.name === updatedDynamicFilter.name) {
              dynamicFilter.isActive = true;
            }else {
              dynamicFilter.isActive = false
            }
        })}
        // ON SELECTION OF DYNAMIC MENU FACET
        let dynFilterStr = "";
        if (updatedDynamicFilter.selectedFilterValues) {
          state.dynamicFilters?.map((dynamicFilter: DynamicFilter) => {
            if( dynamicFilter.name === updatedDynamicFilter.name) {
              dynamicFilter.selectedFilterValues = updatedDynamicFilter.selectedFilterValues;
              state.activeDynFilterName = dynamicFilter.name;
              state.selectedFacetsList[dynamicFilter.name] = dynamicFilter.selectedFilterValues;
            }
            if(dynamicFilter.selectedFilterValues && dynamicFilter.selectedFilterValues.length > 0) {
              dynFilterStr += dynFilterStr ? "~" : "";
              dynFilterStr += dynamicFilter.name+":"+dynamicFilter.selectedFilterValues;
            }
        })
        state.dynamicFilterSelection = dynFilterStr;
      }
      }
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, action:any) => {
          //console.log("----------->PRODUCT SLICE HYDRATE : <------------ " );
          //console.log("HYDRATE STATE : -------->  : " + JSON.stringify(state))
          //console.log("HYDRATE action.payload.reducer : -------->  : " + JSON.stringify(action.payload.reducer))
          return {
            ...state,
            ...action.payload.products
          };
        } )
        builder.addCase(fetchProductDetails.fulfilled, (state, { meta, payload, type }) => {  
            state.productDetailData = payload;
            state.loading = SUCCESS;
            state.currentRequestId = "";
            //console.log(current(state.productDetailData))
        })
        builder.addCase(fetchProductDetails.rejected, (state, { meta, payload, error }) => {
            state.currentRequestId = meta;
            state.loading = FAILED;
            state.productDetailData = payload;
            state.error = error as string;
        })

        builder.addCase(fetchProductSubTypes.fulfilled, (state, { meta, payload, type }) => {
            state.productSubTypesData = payload;
            state.loading = SUCCESS;
            state.currentRequestId = "";
            //console.log("fetchProductSubTypes : fulfilled : "+current(state))
        })
        builder.addCase(fetchProductSubTypes.rejected, (state, { meta, payload, error }) => {
            state.currentRequestId = meta;
            state.loading = FAILED;
            state.productSubTypesData = payload;
            state.error = error as string;
        })

        builder.addCase(fetchProductStyles.fulfilled, (state, { meta, payload, type }) => {
          state.productStylesData = payload;
          state.loading = SUCCESS;
          state.currentRequestId = "";
          //console.log(current(state))
        })
        builder.addCase(fetchProductStyles.rejected, (state, { meta, payload, error }) => {
            state.currentRequestId = meta;
            state.loading = FAILED;
            state.productStylesData = payload;
            state.error = error as string;
        })

        builder.addCase(fetchProductsData.fulfilled, (state, { meta, payload, type }) => {
          state.productsData = payload;
          state.loading = SUCCESS;
          state.currentRequestId = "";
          //console.log(current(state))
        })
        builder.addCase(fetchProductsData.rejected, (state, { meta, payload, error }) => {
            state.currentRequestId = meta;
            state.loading = FAILED;
            state.productsData = payload;
            state.error = error as string;
        })

        //---------------------------- Category page ---------------

      builder.addCase(fetchMainMenuFilters.fulfilled, (state, { meta, payload, type }) => { 
          state.mainMenuFilters = payload.filters;
          state.mainMenuFiltersLoading = SUCCESS;
          state.currentRequestId = "";
          //console.log("Slice: mainMenuFilters: "+ state.mainMenuFilters.length)

      })
      builder.addCase(fetchMainMenuFilters.pending, (state, { meta }) => {
        state.mainMenuFiltersLoading = LOADING;
      })
      builder.addCase(fetchMainMenuFilters.rejected, (state, { meta, payload, error }) => {
          state.currentRequestId = meta;
          state.mainMenuFiltersLoading = FAILED;
          state.error = error as string;
      })

      builder.addCase(fetchSubTypeFilters.fulfilled, (state, { meta, payload, type }) => { 
        state.subTypeFilters = payload.data
        state.subTypeFiltersLoading = SUCCESS;
        state.currentRequestId = "";
        //console.log("Slice: fetchSubTypeFilters: "+ state.subTypeFilters.length)
      })
      builder.addCase(fetchSubTypeFilters.pending, (state, { meta }) => {
        state.subTypeFiltersLoading = LOADING;
      })
      builder.addCase(fetchSubTypeFilters.rejected, (state, { meta, payload, error }) => {
        state.currentRequestId = meta;
        state.subTypeFiltersLoading = FAILED;
        state.subTypeFilters = payload;
        state.error = error as string;
      })

      builder.addCase(fetchProducts.pending, (state, { meta }) => {
        state.productsLoading = LOADING;
      })
      builder.addCase(fetchProducts.fulfilled, (state, { meta, payload, type }) => {
        state.products = payload.products;
        state.productsLoading = SUCCESS

        let newDynFilters = Object.entries(payload.filters)
        .filter(([name ,filterItems]) => name !== state.mainMenuFilterType && (filterItems as [])?.length > 0)
        .map(( [name, filterItems], index: number ) => (
          { name: name,
            filterItems:  filterItems,
            isActive: (!state.activeDynFilterName && index === 0) ? true : (state.activeDynFilterName === name),
            selectedFilterValues: state.selectedFacetsList[name] || []} as DynamicFilter));
            
        //Merging existing dynamic filters with new
        /* if(state.dynamicFilters && state.dynamicFilters.length > 0) {
          state.dynamicFilters.map((existingDynfilter: DynamicFilter) => {
            newDynFilters.map( (newDynFilter:DynamicFilter) => {
              if(existingDynfilter.name === newDynFilter.name) {
                let newDynFilterItems = newDynFilter.filterItems?.flatMap( filterItem => { return filterItem.filterValue});
                existingDynfilter.filterItems?.map((dfi: DynamicFilterItem) => {
                    dfi.isActive = newDynFilterItems?.includes(dfi.filterValue) ? true : false;
                })
              }
            })
          })
        } else {
          state.dynamicFilters = newDynFilters;
        } */

        state.dynamicFilters = newDynFilters;
        
        let imageUrls:string[] = []
        state.products.map((product:any) => { 
          if(product.productDesignImage) {
            imageUrls.push(product.productDesignImage)
          }
        });
        state.productDesignImages = imageUrls;
        state.currentRequestId = "";
        //console.log("Slice: fetchProducts: "+ state.products.length)
        //console.log("Slice: productDesignImages: "+ state.productDesignImages.length)
      })
      builder.addCase(fetchProducts.rejected, (state, { meta, payload, error }) => {
          state.currentRequestId = meta;
          state.productsLoading = FAILED;
          state.products = payload;
          state.error = error as string;
      })
    }
});

export const { actions, reducer } = productsSlice;
export default reducer;

export function useProductsData() {
  const dispatch = useDispatch();

  const {
    productType,
    mainMenuFilterType,
    mainMenuFilterName,
    subTypeFilterName,
    subTypeFilterDisplayName,
    productId,
    filtersLoaded, 
    productsLoading,
    mainMenuFiltersLoading,
    subTypeFiltersLoading

  } = useSelector((state: any ) => state.productCatPge); 
  
  const dispatchFetchMainMenuFilters = () => dispatch(fetchMainMenuFilters({productTypeName : productType}) as any)
  const dispatchFetchSubTypeFilters = () => 
    dispatch(fetchSubTypeFilters({productTypeName: productType, mainMenuFilterType: mainMenuFilterType, mainMenuFilterName: mainMenuFilterName  })  as any)
  
  const dispatchFetchProducts = () => { 
    let filter="";
    if(mainMenuFilterName) {
        filter = mainMenuFilterType + ":" + mainMenuFilterName;
    }
    if(subTypeFilterName){
      filter += filter ? "~" : "";
      filter += "productSubTypeName:"+subTypeFilterName;
    }
    if(dynamicFilterSelection) {
      filter += filter ? "~" : "";
      filter += dynamicFilterSelection;
    }
    productType && dispatch(fetchProducts({productTypeName : productType, filterBy: filter }) as any)
  }

  const dispatchFetchProductDetails = () => dispatch(fetchProductDetails({productId : productId}) as any)


  const mainMenuFilters = useSelector((state: any) => state.productCatPge.mainMenuFilters);
  const subTypeFilters = useSelector((state: any) => state.productCatPge.subTypeFilters);
  const products = useSelector((state: any) => state.productCatPge.products);
  const dynamicFilters: DynamicFilter[] = useSelector((state: any) => state.productCatPge.dynamicFilters);
  const dynamicFilterSelection: string = useSelector((state: any) => state.productCatPge.dynamicFilterSelection);
  const productDesignImages: string[] = useSelector((state: any) => state.productCatPge.productDesignImages)
  const productDetails: string[] = useSelector((state: any) => state.productCatPge.productDetailData)

  const setProductType = (productType: string) =>   dispatch(actions.setProductType(productType) as any)
  const setProductId = (productId: string) =>   dispatch(actions.setProductId(productId) as any)
  const setMainMenuFilterTypeSelected = (shopBySelection: string) =>   dispatch(actions.mainMenuFilterTypeSelected(shopBySelection) as any)
  const setMainMenuFilterNameSelected = (mainMenuFilterName: string) =>   dispatch(actions.mainMenuFilterNameSelected(mainMenuFilterName) as any)
  const setSubTypeFilterNameSelected = (subTypeFilterName: string, subTypeFilterDisplayName: string) =>   dispatch(actions.subTypeFilterNameSelected({subTypeFilterName, subTypeFilterDisplayName}) as any)
  const updateMainMenu = (type:string, filters:Filter[]) =>   dispatch(actions.updateMainMenu({type, filters}) as any)
  const updateDynamicMenuSelection = (dynamicFilter:DynamicFilter) =>   dispatch(actions.updateDynamicMenuSelection(dynamicFilter) as any)




  return {

    dispatchFetchMainMenuFilters,
    dispatchFetchSubTypeFilters,
    dispatchFetchProducts,
    dispatchFetchProductDetails,

    mainMenuFilters,
    filtersLoaded,
    subTypeFilters,
    products,
    dynamicFilters,
    dynamicFilterSelection,
    productDesignImages,
    productDetails,

    productId,
    productType,
    mainMenuFilterType,
    mainMenuFilterName,
    subTypeFilterName,
    subTypeFilterDisplayName,

    setProductId,
    setProductType,
    setMainMenuFilterTypeSelected,
    setMainMenuFilterNameSelected,
    setSubTypeFilterNameSelected,
    updateMainMenu,
    updateDynamicMenuSelection,

    fetchProducts,

    productsLoading,
    mainMenuFiltersLoading,
    subTypeFiltersLoading

  }
}

